import ReactDOM from 'react-dom';
import React,{useState, useEffect, useContext} from 'react'; 
import $ from 'jquery';
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Context } from '../../components/Context';
import { Modal,Button, Tooltip, OverlayTrigger} from "react-bootstrap";
import { toastNotify } from '../../components/Helper';
import DeleteConfirmation from "../../components/DeleteConfirmation";
import Datatables, { reloadDataTable, reloadUrlDataTable } from '../../components/Tabel/Datatables';
import {fetchData, deleteRequest }  from '../../components/Services/Api';

function AllUsers() {
 
    window.document.title = "All Members | I2R Portal";
    const assetURL = process.env.REACT_APP_ASSET_URL;
    const navigate = useNavigate();

    const [tinyloader, setTinyloader] = useState(false);
    const { handleSubmit,register, setValue } = useForm();
    const [ reload, setReload ] = useState(false);
    const [ loginLoader, setLoginLoader ] = useState(false);
    const [id, setId] = useState(null);
    const [client, setClient] = useState([]);
    const [updateRecord, setUpdateRecord] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);
    const [displayManageUserModal, setDisplayManageUserModal] = useState(false);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState('all');
    const [statusOption, setStatusOption] = useState('all');

    const [user, setUser] = useContext(Context);

    const [formData, setFormData] = useState({
        id : null,
        username: '',
        password: '',
        name: '',
        email: '',
        type: 3
    });

    const [dt] = useState({
        dt_url: `users/list/all`,
        dt_name: 'user_list',
        dt_export : false,
        dt_column: [
            { data:"id", name:'id', title: "#ID"},
            { data:"name", name:'name', title:"Name"},
            { data:"email", name:'email', title:"Email", class:"text-nowrap"},
            { data:"username", name:'username',  title:"Username", class:"text-nowrap"},
            { data:"role", name:'role',  title:"Role", class:"text-nowrap" },
            { data:"status", name:'status',  title:"Status", class:"text-nowrap" },
            { data:"created_at", name:'created_at',  title:"Registered on", class:"text-nowrap" },
            { data:"id", title:"Action", sortable:false, searchable:false, orderable: false, class:"text-nowrap text-center", width:160}
        ],
        dt_column_defs: [
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<>
                        <div className="text-nowrap">
                         { (rowData.type === 1) && <span className="text-danger">Admin</span> }
                         { (rowData.type === 2) && <span className="text-primary">Staff</span> }
                         { (rowData.type === 3) && <span className="text-muted">Customer</span> }
                        </div>                             
                    </>, td)
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<>
                        <div className="text-nowrap">
                         { (rowData.suspend === 0) && <span className="badge bg-success">Active</span> }
                         { (rowData.suspend === 1) && <span className="badge bg-danger">Suspended</span> }
                        </div>                             
                    </>, td)
                }
            },
            {
                targets: 7,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<>
                       <div className="text-nowrap">
                        { ( user.allowed.includes('user-update') || user.allowed.includes('user-access-portal') ) &&
                            <button className="btn btn-sm btn-success" onClick={() => manageUser(rowData)}>Manage</button>
                        }
                        { (user && user.allowed && user.allowed.includes('user-update')) && 
                            <button className="btn btn-sm btn-primary mx-1" onClick={() => handleUpdate(rowData)} >
                                <svg className="icon tscale-1p1" role="img"><use href="#icon_edit" /></svg>
                            </button>
                        }
                        { (user && user.allowed && user.allowed.includes('user-delete')) && 
                            <button className="btn btn-sm btn-danger mx-1" onClick={() => handleDelete(rowData.id)}>
                                <svg className="icon tscale-1p1" role="img"><use href="#icon_trash" /></svg>
                            </button>
                        }
                        </div>                           
                    </>, td)
                }
            },
        ]
    });

    useEffect(() => {
        reloadUrlDataTable(dt, `users/list/${selectedOption}/${statusOption}`);
        if(!$('.dt-custom-filter select').hasClass('userrole')){
            $('.dt-custom-filter').append(`
            <select class="userrole form-select form-select-sm mb-1 float-end w-auto ms-1 ms-md-0 d-inline-block" onchange="SelectRoleFilter(this.value)">
                <option value="all">All Role(s)</option>
                <option value="1">Admin</option>
                <option value="2">Staff</option>
                <option value="3">Customer</option>
            </select>`);
        }
        if(!$('.dt-custom-filter select').hasClass('status')){
            $('.dt-custom-filter').append(`
            <select class="status form-select form-select-sm w-auto d-inline-block me-sm-3 float-end" onchange="SelectStatusFilter(this.value)" 
            value="all">
                <option value="all">Status</option>
                <option value="0">Active</option>
                <option value="1">Suspended</option>
            </select>`);
        }
    },[reload]);

    window.SelectRoleFilter = (option) => {
        setSelectedOption(option);
        //setStatusOption('all');
        reload === false ? setReload(true) : setReload(false);
    }

    window.SelectStatusFilter = (option) => {
        setStatusOption(option);
       // setSelectedOption('all');
       reload === false ? setReload(true) : setReload(false);
    }

    async function onClientSubmit(data) {

        setTinyloader(true);
        const method = data.id>0 ? 'put' : 'post';
        const url    = data.id>0 ? `users/updateuser/${data.id}` : 'users/adduser';
        
        try {
            fetchData(url, data, method, (responce)=> {
                setTinyloader(false);
                if(responce.success){
                    setDisplayModal(false);
                    toastNotify('success', responce.message);
                    reloadDataTable(dt);
                }else{
                    if(responce.message === 'validation_error'){
                        [responce.data].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    }
                    else {
                        toastNotify('danger', responce.message)
                    }
                }
            }); 
        } catch (err) {
            toastNotify('danger', 'Invalid action perform. Please try again later');
            setTinyloader(false);
        }
    }

    async function handleDelete(id){
        setId(id);
        setDisplayConfirmationModal(true);
    }

    async function SelectRoleOption(option){
        setFormData(prevState => ({
            ...prevState,
            type: option
        }));
        setValue("type", Number(option));
    }
    
    // Handle the actual updation of the item
    async function handleUpdate(row){
        
        setValue("id", Number(row.id));
        setValue("username", row.username);
        setValue("name", row.name);
        setValue("email", row.email);
        setValue("type", Number(row.type));

        setUpdateRecord(true);

        setTimeout(()=> {
            setDisplayModal(true)  
        },100)  
    }
    // Manage Client accout - clicek on grid action btn
    async function manageUser(row){
        setClient({
            id : Number(row.id),
            name : row.name,
            status : row.suspend
        });
        setTimeout(()=> {
            setDisplayManageUserModal(true); 
        },100)
    }
    // Manage Client accout - temparery deactivate account(if activated)
    async function suspendAccount(id){
        fetchData('users/update-status', {id: id}, 'post', (responce)=> {
            if(responce.success){
                let NotifyClass = responce.data.suspend ? 'success' : 'danger';
                toastNotify(NotifyClass, responce.message);
                setDisplayManageUserModal(false); 
                reloadDataTable(dt);
            }else{
                if(responce.message === 'validation_error'){
                    [responce.data].forEach((ele) => {
                        Object.keys(ele).map((v, i) => {
                            return toastNotify('danger', ele[v])
                        });
                    })
                }
                else {
                    toastNotify('danger', responce.message)
                }
            }
        }); 
    }

    // Manage Client accout - Direct login to any account
    async function accessPortal(id){

        setLoginLoader(false);

        fetchData('direct-login', {id: id}, 'post', (response)=> {
            
            setLoginLoader(true);
            setDisplayManageUserModal(false); 

            if(response.success){ 

                localStorage.removeItem('i2r-token');
                localStorage.removeItem('i2r-user'); 

                setUser(response.data.user);

                document.getElementById("profile_image").src = `${assetURL}assets/images/avatar/${response.data.user.profile_image}`;
                document.getElementById("header_profile_name").innerHTML = response.data.user.name;

                localStorage.setItem('switch-account', true);
                localStorage.setItem('i2r-token',response.data.token);
                localStorage.setItem('i2r-user',JSON.stringify(response.data.user)); 
                
                setTimeout(()=>{ 
                    navigate('../dashboard');
                    setLoginLoader(false);
                },500);

            }else{
                setLoginLoader(false);
                if(response.message === 'validation_error'){
                    [response.data].forEach((ele) => {
                        Object.keys(ele).map((v, i) => {
                            return toastNotify('danger', ele[v])
                        });
                    })
                }
                else {
                    toastNotify('danger', response.message)
                }
            }
        }); 
    }

    // Handle the actual deletion of the item
    const submitDelete = (id) => {
        deleteRequest(`users/delete/${id}`,(responce)=> {
            setDisplayConfirmationModal(false);
            if(responce.success){
                toastNotify('success', responce.message);
                reloadDataTable(dt);
            }else{
                toastNotify('danger', responce.message);
            }
        }); 
    }
   
    const openNewUserPopup = () => {
        setValue("id", 0);
        setValue("username", '');
        setValue("name", '');
        setValue("email", '');
        setValue("type", '3');
        setFormData([]);
        setDisplayModal(true);
    };

     // Hide the modal
    const hideConfirmationModal = () => {

        setDisplayConfirmationModal(false);
        setDisplayModal(false);
        setDisplayManageUserModal(false); 

        setValue("id", 0);
        setValue("username", '');
        setValue("name", '');
        setValue("email", '');
        setValue("type", '3');

        setFormData([]);
    }; 

    return(
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row clearfix">
                        <div className="col-md-10">
                            <h4 className="page-heading">All Members</h4>
                        </div>
                        <div className="col-auto ms-auto col-md-2 mb-2 mt-2">
                            { (user && user.allowed && user.allowed.includes('user-create')) &&
                                <OverlayTrigger placement="left" overlay={<Tooltip> Click here to add new user </Tooltip>}>
                                    <Button variant='primary' onClick={() => openNewUserPopup() } className="px-3 text-nowrap float-end" >
                                        <svg className="icon tscale-1p3 me-1 pe-none" role="img"><use href="#icon_plus" /></svg> Add New
                                    </Button>
                                </OverlayTrigger>
                            }
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body">
                                <Datatables dt_name="user_list" dataPageLength="15"/>
                                <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} id={id} message={'Are you sure you want to delete this record?'}  />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal show={displayModal} onHide={hideConfirmationModal} className="modal" id="addnewclients">
                <div className="modal-header">
                    <h5 className="modal-title" id="addnewclientsLabel">Add New</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                </div>
                <div className="modal-body">
                   <form id='myfrom' onSubmit={handleSubmit(onClientSubmit)} autoComplete="off">
                        <input type="hidden" className="form-control" id='user_id' name="id" {...register('id')} defaultValue={formData.id ? formData.id : null} maxLength={32}/>
                        <div className="row mb-3 justify-content-center">
                            <label htmlFor="username" className="col-sm-3 col-form-label">Username<strong className="text-danger"> *</strong></label>
                            <div className="col-sm-8">
                            <input type="text" className="form-control" id='username' required name="username" defaultValue={formData.username} placeholder="Username" {...register('username')} maxLength={32}/>
                            </div>
                        </div>
                        <div className="row mb-3 justify-content-center">
                            <label htmlFor="username" className="col-sm-3 col-form-label">Password { updateRecord === false ? <strong className="text-danger"> *</strong> : '' }</label>
                            <div className="col-sm-8">
                                { updateRecord === false ? 
                                    <input required type="password" className="form-control" id='password' name="password" placeholder="Password" {...register('password')} maxLength={32}/>
                                : 
                                    <input type="password" className="form-control" id='password' name="password" placeholder="Password" {...register('password')} maxLength={32}/>
                                }
                            </div>
                        </div>
                        <div className="row mb-3 justify-content-center">
                            <label htmlFor="username" className="col-sm-3 col-form-label">Name<strong className="text-danger"> *</strong></label>
                            <div className="col-sm-8">
                            <input type="text" className="form-control" id='name' name="name" defaultValue={formData.name} required placeholder="Name" {...register('name')} maxLength={32}/>
                            </div>
                        </div>
                        <div className="row mb-3 justify-content-center">
                            <label htmlFor="username" className="col-sm-3 col-form-label">Email<strong className="text-danger"> *</strong></label>
                            <div className="col-sm-8">
                            <input type="email" className="form-control" id='email' name="email" defaultValue={formData.email} required placeholder="Email" {...register('email')} maxLength={32}/>
                            </div>
                        </div>
                        <div className="row mb-3 justify-content-center">
                            <label htmlFor="roles" className="col-sm-3 col-form-label">Role<strong className="text-danger"> *</strong></label>
                            <div className="col-sm-8">
                            <select className="form-select" id='role' name="type" {...register('type')} value={formData.type} onChange={e => SelectRoleOption(e.target.value)}  placeholder="Select Role...">
                                { (user.type && user.type === 1) && <option value={1}>Admin</option> }
                                { (user.type && user.type !== 3 && user.allowed && user.allowed.includes('staff-create')) && <option value={2}>Staff</option> }
                                { (user.allowed && user.allowed.includes('user-create')) && <option value={3}>Customer</option> }
                            </select>
                            </div>
                        </div>
                        <div className="row mb-3 justify-content-center">
                            <div className="col-md-11">
                                <div className="text-end">
                                    <button type="submit" className="btn btn-primary rounded-0" disabled={tinyloader}>
                                        { tinyloader && <span className="spinner-border spinner-border-sm mr-1"></span> } Submit 
                                    </button>  
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal show={displayManageUserModal} onHide={hideConfirmationModal} className="modal" id="namege_user">
                <div className="modal-header">
                    <h5 className="modal-title" id="addnewclientsLabel">{`Manage ${client.name}'s Account`}</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                </div>
                <div className="modal-body">
                    <div className="row mb-3 justify-content-center">
                        <div className="col-md-11">
                            <div className="text-end">
                                { (user && user.allowed && user.allowed.includes('user-update')) && 
                                    <div className="text-end">
                                        {
                                            client.status === 0 
                                            ? 
                                                <button type="button" onClick={() => suspendAccount(client.id)} className="btn btn-danger btn-lg w-100 rounded-0 mb-3">Suspend Account</button>
                                            :
                                                <button type="button" onClick={() => suspendAccount(client.id)} className="btn btn-success btn-lg w-100 rounded-0 mb-3">Activate Account</button>
                                        }
                                    </div>
                                }
                                { (user && user.allowed && user.allowed.includes('user-access-portal')) && 
                                    <button type="button" onClick={() => accessPortal(client.id)} className="btn btn-warning btn-lg w-100 rounded-0 mb-3">
                                    { loginLoader && <div className="spinner-border spinner-border-sm text-right mx-2" role="status"><span className="visually-hidden">Loading...</span></div> } Access portal
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default AllUsers