import $ from 'jquery';
import React,{useState, useEffect, useRef} from 'react'; 
import "select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.min.css";
import { getRequest } from './Services/Api';

function UsersDropDown(props) {
    const userInput = useRef(null);
    const fieldName = props.name ? props.name : 'client';
    const [options, setOptions] = useState(null);

    props.form.setValue(fieldName, props.defaultVal);

    useEffect(() => {
        $(".select2").select2({
            theme: "bootstrap-5",
            placeholder: 'Please select customer ...',
            allowClear: false
        });

        getRequest('users/listing', (response)=> {
            if(response.success){
                setOptions(response.data);
            }
        });
    },[]);

    if(props.defaultVal){
        $(".select2-dropdown").select2({
            placeholder: 'Please select customer ...',
            theme: "bootstrap-5",
            allowClear: true,
            'val': props.defaultVal
        });
    }
    
    return (
        <div className='position-relative overflow-hidden' onFocus={()=>props.dropDownChange(userInput)}>
            <select value={props.defaultVal} className="form-control select2 select2-dropdown rounded-0" name={fieldName} {...props.form.register(fieldName, {required: (props.required && props.required === "true") ? true : false })} ref={userInput}>
                { (options && options.customer && options.customer.length>0)
                    && <optgroup label="Our Customers">
                        {  options.customer.map(owner =>
                            <option key={ owner.value } value={ owner.value }>{ owner.label }</option>
                            )
                        };
                    </optgroup>
                }
                { (options && options.staff && options.staff.length>0) 
                && <optgroup label="Staff Members">
                        { (options && options.staff && options.staff.length>0) ? options.staff.map(owner =>
                            <option key={ owner.value } value={ owner.value }>{ owner.label }</option>
                        ) :
                        <option key='0' value=''>No staff(s) found.</option>
                        };
                    </optgroup>
                }
                { (options && options.admin && options.admin.length>0)
                    && <optgroup label="Site Administrators">
                        { (options && options.admin && options.admin.length>0) ? options.admin.map(owner =>
                            <option key={ owner.value } value={ owner.value }>{ owner.label }</option>
                            )
                            :
                            <option key='0' value=''>No admin(s) found.</option>
                        };
                    </optgroup>  
                }
            </select>
        </div>
    );
}
export default UsersDropDown