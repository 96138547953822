import { Modal } from "react-bootstrap";
import { downloadFile, noimageHandle } from '../components/Helper';

function ImagePopup(props) {
    const assetURL = process.env.REACT_APP_ASSET_URL;
    const imageUrl = `${assetURL}${props.fileName}`;
    return (
        <>
            <Modal show={props.showModal} onHide={props.hideModal} className="modal" id="view_images">
                <div className="modal-header">
                    <h5 className="modal-title">I2R Portal</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={props.hideModal}></button>
                </div>
                <div className="modal-body">
                    <img src={imageUrl} style={{ width: '100%'}} alt="" onError={noimageHandle} /> 
                </div>
                { (props.user && props.user.allowed && props.user.allowed.includes('site-inspection-findings-manage')) &&
                    <Modal.Footer>
                        <button className="btn btn-sm btn-success" onClick={() => downloadFile(props.fileName)}>
                            <svg className="icon tscale-1p3 me-2 pe-none" role="img"><use href="#icon_download" /></svg> Download
                        </button>
                    </Modal.Footer>
                }
            </Modal>
        </>
    );
}
export default ImagePopup