import $ from 'jquery';
import  '../../components/Select2'; 
import React,{useRef, useContext, useState, useEffect} from 'react'; 
import { format } from 'date-fns';
import { useNavigate, Link } from "react-router-dom";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Context } from '../../components/Context';
import  MapContainer from '../../components/GoogleMap';
import DatepickerComponent from '../../components/DatepickerComponent';
import { toastNotify, downloadFile } from '../../components/Helper'; 
import TestReports from '../Sites/Tabs/TestReports';
import { useForm } from 'react-hook-form';
import '../../components/Select2';
import { getRequest, fetchData }  from '../../components/Services/Api';

function Dashboard() {

    window.document.title = "Dashboard | I2R Portal";
    const navigate = useNavigate();
    localStorage.removeItem('site-tab');
    const opt = [];

    const form = useForm();
    const { register, handleSubmit, setValue, formState: { errors, isSubmitting } } = form;
    const [tinyloader, setTinyloader] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);
    const [displayShuddownModal, setDisplayShuddownModal] = useState(false);
    const [displayMapModal, setDisplayMapModal] = useState(false);
    const [ reload, setReload ] = useState(false);
    const [site, setSite] = useState({});
    const [checkAttention, setCheckAttention] = useState(0);
    const [checkInspection, setCheckInspection] = useState(0);

    const [lastInspection, setLastInspection] = useState(null);
    const [nextInspection, setNextInspection] = useState(null);
    const [lastShutdown, setLastShutdown] = useState(null);
    const [nextShutdown, setNextShutdown] = useState(null);
    const [nextInspectionFlag, setNextInspectionFlag] = useState(0);
    const [nextShutdownFlag, setNextShutdownFlag] = useState(0);
    
    const [user, setUser] = useContext(Context);
    const site_id = (user && user.site && user.site.id) ? user.site.id : null;
    const site_name = (user && user.site && user.site.name) ? user.site.name : null;

    const [siteName, setSiteName] = useState(site_name);
    const [siteLocation, setSiteLocation] = useState(null);
    
    const [siteId, setSiteId] = useState(site_id);
    const [testDetails, setTestDetails] = useState();
    const iconMarker = './images/mappin2.png';

    if(localStorage.getItem('switch-account')){
        localStorage.removeItem('switch-account');
        window.location.reload(false);
    }

    if(user && user.site){
        localStorage.removeItem('reload');
    }else{
        if(localStorage.getItem('reload')){
            navigate('/login')
        }else{
            localStorage.setItem('reload',true);
            window.location.reload(false);
        }
    }

    const [options, setOptions] = useState({value: site_id , label:site_name});
    const [activeSite, setActiveSite] = useState({value: site_id, label:site_name});

    const siteInput = useRef(null);
    setValue('site', site_id);

    useEffect(() => {
        $(".select2").select2({
            theme: "bootstrap-5",
            dropdownParent: $("#switch_sites")
        });
        getRequest(`site/dashboard/${siteId ? siteId : site_id}`, (response)=> {
            if(response.success){
                setSite(response.data);
                setLastInspection(response.data.last_inspection);
                setNextInspection(response.data.next_inspection);
                setLastShutdown(response.data.last_shutdown);
                setNextShutdown(response.data.next_shutdown);

                setCheckAttention(response.data.attention_count);
                setCheckInspection(response.data.inspections);

                setNextInspectionFlag(response.data.next_inspection_flag);
                setNextShutdownFlag(response.data.next_shutdown_flag);

                setTestDetails(response.data.other.tests);

                getSiteList(activeSite);

                setReload(true); 
            }
        }); 
    },[reload, siteId]);

    const getSiteList = (defaultSite) => {
        getRequest('site/list', (response)=> {
            if(response.success){
                setValue('site', defaultSite.value);
                response.data.map((option, index) => (
                    opt.push({
                        value: option.id, 
                        label: option.name 
                    })
                ));
            }
            setOptions(opt);
        }); 
    }
    const siteChange = () => {
        const selectedValue = siteInput.current.value;
        if(selectedValue){
            const selectedLabel = siteInput.current.options[siteInput.current.selectedIndex].text;
            setSiteId(selectedValue);
            setValue('site',selectedValue);
            setUser(previousState => ({ 
                ...previousState, 
                    site : {id: selectedValue, name: selectedLabel}
            }));
        }
    }

    async function onSiteSubmit(data) {

        var e = document.getElementById("customerSite");
        var selectedIndex = data.site;
        var siteLabel = e.options[e.selectedIndex].text;

        setActiveSite({value: selectedIndex, label:siteLabel});
        localStorage.setItem('i2r-user', JSON.stringify(user));

        setUser(previousState => ({
            ...previousState,
            site : {id: selectedIndex, name:siteLabel}
        }));
        
        document.getElementById("switch_modal_close").click();
        toastNotify('success', `Now current site is <em><b> ${siteLabel} </b></em>`);

        try {
            fetchData(`user/update_site`, { site_id : selectedIndex, user_id: user.id ? user.id : 0 }, 'post', (responce)=> {
                setTinyloader(false);
                if(!responce.success){
                    if(responce.message === 'validation_error'){
                        [responce.data].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    }
                    else {
                        toastNotify('danger', responce.message)
                    }
                }
                reload === false ? setReload(true) : setReload(false);
            }); 
        } catch (err) {
            toastNotify('danger', 'Invalid action perform. Please try again later');
            reload === false ? setReload(true) : setReload(false);
        }
    }

    async function onInspSubmit(data) {
        let formData = new FormData(document.getElementById('inspectionFindingsform'));
        let tabname = data.tabname ? data.tabname : '';
        saveFormData(formData, tabname);
    }

    async function saveFormData(data, tabname = '') {
        try {
            fetchData(`site/manage/${siteId}/${tabname}`, data, 'post', (responce)=> {
                if(responce.success){
                    toastNotify('success', responce.message);
                    hideConfirmationModal();
                    reload === false ? setReload(true) : setReload(false);
                }else{
                    if(responce.message === 'validation_error'){
                        [responce.data].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    }
                    else {
                        toastNotify('danger', responce.message)
                    }
                }
            }); 
        } catch (err) {
            toastNotify('danger', 'Invalid action perform. Please try again later');
        }
    };

    const testsReload = (action = false) => {
        if(action === true){
            reload === false ? setReload(true) : setReload(false);
        }
    }

    async function attentionRegirect(site_id) {
        localStorage.setItem('site-tab','Inspection-Findings');
        navigate(`../site/view/${site_id}`)
    }
    async function siteSummeryRegirect(site_id) {
        localStorage.setItem('site-tab','Summary-Status');
        navigate(`../site/view/${site_id}`)
    }

    // Show Google Map Model
    const handleGoogleMap = (siteName = '', Address = '', latitude = '', longitude = '') => {
        setSiteName(siteName);
        setSiteLocation({
            name: siteName ? siteName : 'I2R Portal',
            address: Address ? Address : '',
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
        });
        setDisplayMapModal(true);
    }

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayModal(false);
        setDisplayShuddownModal(false);
        setDisplayMapModal(false);
    };

    return(
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between mb-md-2">
                        <div className="col mb-2">
                            <h4 className="page-heading">Dashboard 
                                { (user && user.allowed && user.allowed.includes('site-view')) && 
                                    <OverlayTrigger placement="right" overlay={<Tooltip> View all sites listing</Tooltip>}>
                                        <Link to="../site/index" className='ms-4 btn btn-outline-primary btn-sm rounded'>All Sites</Link>
                                    </OverlayTrigger>
                                }
                            </h4>
                        </div>
                    </div>

                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xxl-2 g-3 g-sm-4">
                        <div className="col">
                            <div className="bg-white d-flex align-items-center h-100 p-3 shadow-sm border-start border-1 border-info">
                                <div className="d-block">
                                    <h5 className="fw-bold mb-1" style={{textTransform: 'capitalize'}}>Hello {user && user.name}</h5>
                                    <p className="m-0 opacity-80">It's good to see you again!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="bg-white d-flex align-items-center h-100 p-3 shadow-sm border-start border-1 border-warning">
                                <div className="d-block">
                                    <p className="m-0 opacity-80">You're now viewing</p>
                                    <h5 className="fw-bold mb-1">{ activeSite.label ? activeSite.label : 'No any site selected'}</h5>
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip> Click here to select your favorite site </Tooltip>}>
                                        <a className="m-0 opacity-80 small text-decoration-none" role="button" rel="noreferrer" data-bs-toggle="modal" data-bs-target="#switch_sites">Click here to change sites</a>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row g-3 g-sm-4 mt-0">
                        <div className="col-sm-12">
                            <div className="card rounded-0 border border-light-purple shadow-sm">
                                <div className="card-header rounded-0 py-3 h5 border-0 fw-medium text-blue bg-light-purple">
                                    Site Details
                                </div>
                                <div className="card-body p-2 p-sm-4">
                                    <div className="row g-2 g-sm-4">
                                        <div className="col-sm-12 col-lg-4">
                                            <div className="h-100 p-3 border-light">
                                                <div className="d-flex mb-2">
                                                    <div className="fw-medium me-2">Address:</div>
                                                    { site && site.address && 
                                                        <>
                                                            <span> { site.address }</span>
                                                            <OverlayTrigger placement="top" overlay={<Tooltip> View on Google Map </Tooltip>}>
                                                                <a className='pointer p-1' onClick={() => handleGoogleMap(site.name, site.address, site.latitude, site.longitude)}> 
                                                                    <img src={iconMarker} width={45} alt="Map View" />
                                                                </a>
                                                            </OverlayTrigger>
                                                        </>
                                                    }
                                                </div>
                                                <div className="d-flex mb-2">
                                                    <div className="fw-medium me-2">Owner:</div>
                                                    <span>{ site && site.owner ? site.owner : 'Not Assigned yet.' }</span>
                                                </div>
                                                <div className="d-flex mb-2">
                                                    <div className="fw-medium me-2">EMA License Details:</div>
                                                    <span>{ site && site.license_no ? site.license_no : 'N/A' }</span>
                                                </div>
                                                <div className="d-flex mb-2">
                                                    <OverlayTrigger placement="bottom" overlay={<Tooltip> Click here to download </Tooltip>}>
                                                        <button className="btn btn-success btn-sm mt-1 me-1" onClick={() => downloadFile(site.license_file, 'licenses')}>Download</button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger placement="bottom" overlay={<Tooltip> Site License expiration information</Tooltip>}>
                                                        <span className="btn btn-outline-danger btn-sm text-center me-1 mt-1 d-inline-block pe-none">Expired on: { site && site.license_expiry ? format(new Date(site.license_expiry), 'dd/MM/yyyy') : 'N/A' }</span>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-lg-8">
                                            <div className='row'>
                                                <div className="col-6 col-lg-3">
                                                    <div className="d-flex align-items-center h-100 p-3 border-light">
                                                        <div className="d-block">
                                                            <h4 className="fw-bold mb-1">{ site && site.installation_type ? site.installation_type : 'N/A' }</h4>
                                                            <p className="m-0 opacity-80">Intake Voltage</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-lg-3">
                                                    <div className="d-flex align-items-center h-100 p-3 border-light">
                                                        <div className="d-block">
                                                            <h4 className="fw-bold mb-1">{ site && site.pg_intakes ? site.pg_intakes : '0' }</h4>
                                                            <p className="m-0 opacity-80">No. of PG Intakes</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-lg-3">
                                                    <div className="d-flex align-items-center p-3 border-light h-100">
                                                        <div className="d-block">
                                                            <h4 className="fw-bold mb-0">{ checkAttention ? checkAttention : 0 }</h4>
                                                            <p className="mb-0 opacity-80">Requires Attention</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-lg-3">
                                                    <div className="d-flex align-items-center p-3 border-light h-100">
                                                        <div className="d-block">
                                                            <h4 className="fw-bold mb-0">{ checkInspection ? checkInspection : 0 } 
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>View more about inspection findings</Tooltip>}>
                                                                    <span className='px-2' role="button" onClick={() => attentionRegirect(siteId)}>
                                                                        <svg className="icon" title="GoTo"><use href="#icon_rightarrow" style={{padding:'10px'}} /></svg>
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </h4>
                                                            <p className="mb-0 opacity-80">Inspection Findings (This Month)</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="table-responsive" role="tabpanel">
                                            <table className="table-common">
                                                <tbody>
                                                    <tr className='shadow-sm'>
                                                        <td className='border-top'>Inspection</td>
                                                        <td className='border-top'>
                                                            Last Inspection Date:
                                                            <span className="d-block fw-bold">
                                                                { lastInspection ? format(new Date(lastInspection), 'dd/MM/yyyy') : 'N/A' }
                                                            </span>
                                                            
                                                        </td>
                                                        <td className='border-top border-top'>
                                                            Next Inspection Due:
                                                            <span className="d-block fw-bold">
                                                                { nextInspection ? format(new Date(nextInspection), 'dd/MM/yyyy') : 'N/A' }
                                                            </span>
                                                        </td>
                                                        <td className="border-top text-nowrap">
                                                            { (user && user.allowed && user.allowed.includes('site-inspection-edit')) && 
                                                                <OverlayTrigger placement="left" overlay={<Tooltip>Edit site inspection details</Tooltip>}>
                                                                    <button className="btn btn-sm btn-primary me-2" onClick={() => {setDisplayModal(true); setValue('tabname', 'edit_inspection') } }>
                                                                        <svg className="icon me-2" role="img"><use href="#icon_edit" /></svg>
                                                                        Edit
                                                                    </button>
                                                                </OverlayTrigger>
                                                            }
                                                            { (user && user.allowed && user.allowed.includes('site-summary-view')) && 
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>View more about site Summary</Tooltip>}>
                                                                    <button type="button" className="btn btn-success btn-sm ms-2" onClick={() => siteSummeryRegirect(siteId)}>More Info</button>
                                                                </OverlayTrigger> 
                                                            }
                                                        </td>
                                                        <td className='border-top'>
                                                            { Number(nextInspectionFlag) === 0 
                                                                ? 
                                                                <svg className="icon me-2 tscale-1p4 text-success" role="img"><use href="#icon_tick" /></svg>
                                                                : 
                                                                <svg className="icon me-2 tscale-1p4 text-danger" role="img"><use href="#icon_danger" /></svg>
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr className='shadow-sm'>
                                                        <td>Shutdown Servicing</td>
                                                        <td>
                                                            Last Inspection Date:
                                                            <span className="d-block fw-bold">
                                                            { lastShutdown ? format(new Date(lastShutdown), 'dd/MM/yyyy') : 'N/A' }
                                                            </span>
                                                            
                                                        </td>
                                                        <td>
                                                            Next Inspection Due:
                                                            <span className="d-block fw-bold">
                                                                { nextShutdown ? format(new Date(nextShutdown), 'dd/MM/yyyy') : 'N/A' }
                                                            </span>
                                                        </td>
                                                        <td className="text-nowrap">
                                                            { (user && user.allowed && user.allowed.includes('site-edit')) && 
                                                                <OverlayTrigger placement="left" overlay={<Tooltip>Edit site shutdown details</Tooltip>}>
                                                                    <button className="btn btn-sm btn-primary me-2" onClick={() => {setDisplayShuddownModal(true); setValue('tabname', 'edit_shutdown') }}>
                                                                        <svg className="icon me-2" role="img"><use href="#icon_edit" /></svg>
                                                                        Edit
                                                                    </button>
                                                                </OverlayTrigger>
                                                            }
                                                            { (user && user.allowed && user.allowed.includes('site-summary-view')) && 
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>View more about site Summary</Tooltip>}>
                                                                    <button type="button" className="btn btn-success btn-sm ms-2" onClick={() => siteSummeryRegirect(siteId)}>More Info</button>
                                                                </OverlayTrigger>
                                                            }
                                                        </td>
                                                        <td>
                                                            { Number(nextShutdownFlag) === 0 
                                                                ? 
                                                                <svg className="icon me-2 tscale-1p4 text-success" role="img"><use href="#icon_tick" /></svg>
                                                                : 
                                                                <svg className="icon me-2 tscale-1p4 text-danger" role="img"><use href="#icon_danger" /></svg>
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 g-sm-4 mt-0">
                        { (user && user.allowed && user.allowed.includes('tests-view')) && 
                            <TestReports siteId={siteId} details={testDetails} user={user} page="dashboard" confirmAction={testsReload} />
                        }
                    </div>

                </div>
                {/* Switch site popup model */}
                <div className="modal fade" id="switch_sites" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="addnewclientsLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addnewclientsLabel">Switch Sites</h5>
                                <button type="button" className="btn-close" id="switch_modal_close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={handleSubmit(onSiteSubmit)}>
                                    <div className="row mb-3 justify-content-center">
                                        <label htmlFor="site" className="col-sm-12 col-form-label">Select a site<strong className="text-danger"> *</strong></label>
                                        <div className="col-sm-12">
                                            <div className='position-relative overflow-hidden' onFocus={()=>siteChange()}>
                                                <select value={siteId} name="site" {...register('site')} id="customerSite" className="form-control select2" ref={siteInput} >
                                                    {  (options && options.length >0) && options.map((site, ind) =>
                                                        <option key={ind} value={site.value} label={site.label}>{site.label}</option>
                                                       )
                                                    }
                                                </select> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3 justify-content-center">
                                        <div className="col-md-11">
                                            <div className="text-end">
                                                <button type="submit" className="btn btn-primary" disabled={tinyloader}>
                                                {
                                                    !tinyloader ? 'Update'
                                                    :
                                                    <div className="spinner-border spinner-border-sm ms-3" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
            { displayModal && <Modal show={displayModal} onHide={hideConfirmationModal} className="modal" id="add_new_inspection">
                <div className="modal-header">
                    <h5 className="modal-title" id="addnewclientsLabel">Edit Inspection</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                </div>
                <div className="modal-body">
                    <form id='inspectionFindingsform' name='inspectionFindingsform' onSubmit={handleSubmit(onInspSubmit)}>
                        <input type="hidden" name="tabname" { ...register('tabname') } value='edit_inspection' />
                        <input type="hidden" name="site_id" { ...register('site_id') } value={siteId} />
                        <div className="row mb-1 justify-content-center">
                            <label htmlFor="date" className="col-form-label">Date of Last Inspection<strong className="text-danger"> *</strong></label>
                            <div className="">
                                <DatepickerComponent type="date" name="last_inspection" maxDate={new Date()}  defVal={lastInspection} form={form} required="true" errors={errors.last_inspection} /> 
                            </div>
                        </div>
                        <div className="row mb-1 justify-content-center">
                            <label htmlFor="date" className="col-form-label">Due date for Next Inspection<strong className="text-danger"> *</strong></label>
                            <div className="">
                                <DatepickerComponent type="date" name="next_inspection" defVal={nextInspection} form={form} required="true" errors={errors.next_inspection} /> 
                            </div>
                        </div>

                        <div className="row mb-1 justify-content-center mt-3">
                            <div className="col-md-12">
                                <div className="text-end">
                                    <button type="submit" className="btn btn-primary px-4" disabled={isSubmitting}>
                                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            }
            { displayShuddownModal && <Modal show={displayShuddownModal} onHide={hideConfirmationModal} className="modal" id="add_new_inspection">
                <div className="modal-header">
                    <h5 className="modal-title" id="addnewclientsLabel">Edit Shutdown</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                </div>
                <div className="modal-body">
                    <form id='inspectionFindingsform' name='inspectionFindingsform' onSubmit={handleSubmit(onInspSubmit)}>
                        <input type="hidden" name="tabname" { ...register('tabname') } value='edit_shutdown' />
                        <input type="hidden" name="site_id" { ...register('site_id') } value={siteId} />

                        <div className="row mb-1 justify-content-center">
                            <label htmlFor="date" className="col-form-label">Date of Last Shutdown Servicing<strong className="text-danger"> *</strong></label>
                            <div className="">
                                <DatepickerComponent type="date" name="last_shutdown" maxDate={new Date()} defVal={lastShutdown} form={form} required="true" errors={errors.last_shutdown} /> 
                            </div>
                        </div>
                        <div className="row mb-1 justify-content-center">
                            <label htmlFor="date" className="col-form-label">Due date for Next Shutdown Servicing<strong className="text-danger"> *</strong></label>
                            <div className="">
                                <DatepickerComponent type="date" name="next_shutdown" defVal={nextShutdown} form={form} required="true" errors={errors.next_shutdown} /> 
                            </div>
                        </div>

                        <div className="row mb-1 justify-content-center mt-3">
                            <div className="col-md-12">
                                <div className="text-end">
                                    <button type="submit" className="btn btn-primary px-4" disabled={isSubmitting}>
                                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
             </Modal>
            }
            { (displayMapModal && user && user.api_key) && <Modal size={'lg'} show={displayMapModal} onHide={hideConfirmationModal} className="modal" id="displayGoogleMapModal">
                <div className="modal-header">
                    <h5 className="modal-title" id="uploadsLicenceLabel">{ siteName ? siteName : 'I2R Portal' }</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                </div>
                <div className="modal-body">
                    <div className="map-container-model"> 
                        <MapContainer height="400" apiKey={user.api_key} location={siteLocation} zoomLevel={16} />
                    </div>
                </div>
              </Modal>
            }
        </>
    )   
}
export default Dashboard