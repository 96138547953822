import ReactDOM from 'react-dom';
import React,{useState, useEffect, useContext} from 'react'; 
import { Modal,Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { Context } from '../../components/Context';
import { toastNotify } from '../../components/Helper';
import Datatables, { redrawDataTable } from '../../components/Tabel/Datatables';
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { fetchData, deleteRequest }  from '../../components/Services/Api';

function ManageStaff() {
    window.document.title = "All Staff Members | I2R Portal";

    const [tinyloader, setTinyloader] = useState(false);
    const { handleSubmit,register, setValue } = useForm();
    const [ reload, setReload ] = useState(false);
    const [id, setId] = useState(null);
    const [updateRecord, setUpdateRecord] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    const [user] = useContext(Context);
 
    const [formData, setFormData] = useState({
        id : null,
        username: '',
        password: '',
        name: '',
        email: '',
        type: 3
    });

    const [dt] = useState({
        dt_url: `users/list/2`,
        dt_name: 'user_list',
        dt_export : false,
        dt_column: [
            { data:"id", name:'id', title: "#ID"},
            { data:"name", name:'name', title:"Name"},
            { data:"email", name:'email', title:"Email", class:"text-nowrap"},
            { data:"username", name:'username',  title:"Username", class:"text-nowrap"},
            { data:"type", name:'type',  title:"Role", class:"text-nowrap" },
            { data:"suspend", name:'suspend',  title:"Status", class:"text-nowrap" },
            { data:"created_at", name:'created_at',  title:"Registered on", class:"text-nowrap" },
            { data:"id", title:"Action", sortable:false, searchable:false, orderable: false, class:"text-nowrap text-center", width:160},
        ],
        dt_column_defs: [
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<>
                        <div className="text-nowrap">
                         { (rowData.type === 1) && <span className="text-danger">Admin</span> }
                         { (rowData.type === 2) && <span className="text-primary">Staff</span> }
                         { (rowData.type !== 1 && rowData.type !==2) && <span className="text-muted">Customer</span> }
                        </div>                             
                    </>, td)
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<>
                        <div className="text-nowrap">
                         { (rowData.suspend === 0) && <span className="badge bg-success">Active</span> }
                         { (rowData.suspend === 1) && <span className="badge bg-danger">Suspended</span> }
                        </div>                             
                    </>, td)
                }
            },
            {
                targets: 7,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<>
                       <div className="text-nowrap">
                        { (user.type && user.type !== 3 && user.allowed.includes('staff-update')) &&
                            <button className="btn btn-sm btn-primary mx-1" onClick={() => handleUpdate(rowData)} >
                                <svg className="icon tscale-1p1" role="img"><use href="#icon_edit" /></svg>
                            </button>
                        }
                        { (user.type && user.type !== 3 && user.allowed.includes('staff-delete')) && 
                            <button className="btn btn-sm btn-danger" onClick={() => handleDelete(rowData.id)}>
                                <svg className="icon tscale-1p1" role="img"><use href="#icon_trash" /></svg>
                            </button>
                        }
                        </div>                           
                    </>, td)
                }
            },
        ]
    });

    useEffect(() => {
        if(dt){
            redrawDataTable(dt)
        }
    },[reload]);

    // Handle the actual updation of the item
    async function handleUpdate(row){
        
        setValue("id", Number(row.id));
        setValue("username", row.username);
        setValue("name", row.name);
        setValue("email", row.email);
        setValue("type", 2);

        setUpdateRecord(true);

        setTimeout(()=> {
            setDisplayModal(true)  
        },100)  
    }

    async function handleDelete(id){
        setId(id);
        setDisplayConfirmationModal(true);
    }
    // Manage Client accout - temparery deactivate account(if activated)
    async function onClientSubmit(data) {

        setTinyloader(true);
        const method = data.id>0 ? 'put' : 'post';
        const url    = data.id>0 ? `users/updateuser/${data.id}` : 'users/adduser';
        
        try {
            fetchData(url, data, method, (responce)=> {
                if(responce.success){
                    setDisplayModal(false);
                    toastNotify('success', responce.message);
                    reload === false ? setReload(true) : setReload(false);
                }else{
                    if(responce.message === 'validation_error'){
                        [responce.data].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    }
                    else {
                        toastNotify('danger', responce.message)
                    }
                }
            }); 
        } catch (err) {
            toastNotify('danger', 'Invalid action perform. Please try again later');
        }
        setTimeout(()=>{ setTinyloader(false); },500);
    }

    // Handle the actual deletion of the item
    const submitDelete = (id) => {
        deleteRequest(`users/delete/${id}`,(responce)=> {
            setDisplayConfirmationModal(false);
            if(responce.success){
                toastNotify('success', responce.message);
                redrawDataTable(dt);
            }else{
                toastNotify('danger', responce.message);
            }
        }); 
    }
    // Hide the modal
    const hideConfirmationModal = () => {

        setDisplayConfirmationModal(false);
        setDisplayModal(false);

        setValue("id", 0);
        setValue("username", '');
        setValue("name", '');
        setValue("email", '');
        setValue("type", 2);

        setFormData([]);
    };


    return(
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row clearfix">
                        <div className="col-md-10">
                            <h4 className="page-heading">Manage the Staff Accounts</h4>
                        </div>
                        { (user.type && user.type !== 3 && user.allowed && user.allowed.includes('staff-create')) &&
                            <div className="col-auto ms-auto col-md-2 mb-2 mt-2">
                                { (user.type && user.type !== 3) &&
                                    <OverlayTrigger placement="left" overlay={<Tooltip> Click here to add new staff member</Tooltip>}>
                                        <Button variant='primary' onClick={() => setDisplayModal(true) } className="px-3 text-nowrap float-end" >
                                            <svg className="icon tscale-1p3 me-1 pe-none" role="img"><use href="#icon_plus" /></svg> Add New
                                        </Button>
                                    </OverlayTrigger>
                                }
                            </div>
                        }
                    </div>
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body">
                                <Datatables dt_name="user_list" dataPageLength="15"/>
                                <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} id={id} message={'Are you sure you want to delete this record?'}  />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal show={displayModal} onHide={hideConfirmationModal} className="modal" id="addnewclients">
                <div className="modal-header">
                    <h5 className="modal-title" id="addnewclientsLabel">Add New</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                </div>
                <div className="modal-body">
                <form id='myfrom' onSubmit={handleSubmit(onClientSubmit)} autoComplete="off">
                        <input type="hidden" name="id" {...register('id')} defaultValue={formData.id ? formData.id : null} maxLength={32}/>
                        <input type="hidden" name="type" {...register('type')} defaultValue="2"/>
                        <div className="row mb-3 justify-content-center">
                            <label htmlFor="username" className="col-sm-3 col-form-label">Username<strong className="text-danger"> *</strong></label>
                            <div className="col-sm-8">
                            <input type="text" className="form-control" id='username' required name="username" defaultValue={formData.username} placeholder="Username" {...register('username')} maxLength={32}/>
                            </div>
                        </div>
                        <div className="row mb-3 justify-content-center">
                            <label htmlFor="username" className="col-sm-3 col-form-label">Password { updateRecord === false ? <strong className="text-danger"> *</strong> : '' }</label>
                            <div className="col-sm-8">
                            { updateRecord === false ? 
                                <input type="password" className="form-control" id='password' required name="password" placeholder="Password" {...register('password')} maxLength={32}/>
                            : 
                                <input type="password" className="form-control" id='password' name="password" placeholder="Password" {...register('password')} maxLength={32}/>
                            }
                            
                            </div>
                        </div>
                        <div className="row mb-3 justify-content-center">
                            <label htmlFor="username" className="col-sm-3 col-form-label">Name<strong className="text-danger"> *</strong></label>
                            <div className="col-sm-8">
                            <input type="text" className="form-control" id='name' name="name" defaultValue={formData.name} required placeholder="Name" {...register('name')} maxLength={32}/>
                            </div>
                        </div>
                        <div className="row mb-3 justify-content-center">
                            <label htmlFor="username" className="col-sm-3 col-form-label">Email<strong className="text-danger"> *</strong></label>
                            <div className="col-sm-8">
                            <input type="email" className="form-control" id='email' name="email" defaultValue={formData.email} required placeholder="Email" {...register('email')} maxLength={32}/>
                            </div>
                        </div>
                        <div className="row mb-3 justify-content-center">
                            <div className="col-md-11">
                                <div className="text-end">
                                    <button type="submit" className="btn btn-primary rounded-0" disabled={tinyloader}>
                                        { tinyloader && <span className="spinner-border spinner-border-sm mr-1"></span> } Submit 
                                    </button>  
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    )
}
export default ManageStaff