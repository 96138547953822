import React, {useContext, useState, useEffect} from "react";
import { useForm } from 'react-hook-form';
import { Context } from '../../components/Context';
import { toastNotify } from "../../components/Helper";
import {getRequest, fetchData}  from '../../components/Services/Api';

function SiteSettings() {
    window.document.title = "Site Settings | I2R Portal";
    const form = useForm();
    const { register, handleSubmit, setValue, formState: { errors, isSubmitting } } = form;
    const [ reload, setReload ] = useState(false);
    const [settingId, setSettingId] = useState(0);
    const [user] = useContext(Context);

    useEffect(() => {
        getRequest('setting/get', (response)=> {
            if(response.success){
                setSettingId(response.data.id);
                setValue('site_name',response.data.site_name);
                setValue('keywords',response.data.keywords);
                setValue('description',response.data.description);
                setValue('google_api_key',response.data.google_api_key);
                setValue('facebook',response.data.facebook);
                setValue('instagram',response.data.instagram);
                setValue('youtube',response.data.youtube);
                setValue('twitter',response.data.twitter);
                setValue('plan1_fee',response.data.plan1_fee);
                setValue('linkedin',response.data.linkedin);
                setValue('send_fsr_to',response.data.send_fsr_to);
                setValue('send_license_exp_to',response.data.send_license_exp_to);
                setReload(true);
            }
        }); 
    },[reload]);

    async function onSettingSubmit(data) {
        try {
            fetchData(`setting/update/${settingId}`, data, 'put', (response)=> {
                if(response.success){
                    toastNotify('success', response.message);
                    reload === false ? setReload(true) : setReload(false);
                }else{
                    if(response.message === 'validation_error'){
                        [response.data].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    }
                    else {
                        toastNotify('danger', response.message)
                    }
                }
            }); 
        } catch (err) {
            toastNotify('danger', 'Invalid action perform. Please try again later');
        }
    };

    return(
        <>
         { ( user.type && user.type == 1) &&
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between mb-4">
                        <div className="col">
                            <h4 className="page-heading">Site Settings</h4>
                        </div>
                    </div>
                    <div className="bg-white rounded-25 p-4 p-sm-5">
                        <form id='portalSettingform' name='portalSettingform' onSubmit={handleSubmit(onSettingSubmit)} className="align-items-center justify-content-center pt-2">
                            <div className="row">
                                
                                <div className="col-md-6">
                                    <div><h5 className="text-primary">Basic Site Configuration Setting</h5></div>
                                    <div className="form-group">
                                        <label htmlFor="sitename_field" className="col-form-label">Site Name<strong className="text-danger"> *</strong>:</label>
                                        <div className="">
                                            <input type="text" { ...register('site_name', { required: true }) } id="sitename_field" placeholder="Site Name" defaultValue={"I2R Portal"} className={errors.site_name && errors.site_name.type === "required" ? 'is-invalid form-control' : 'form-control' }/>
                                        </div>
                                    </div>
                                    <div className="form-group mt-2">
                                        <label htmlFor="keyword_field" className="col-form-label">Keywords:</label>
                                        <div className="">
                                            <input type="text" { ...register('keywords') } className="form-control" id="keyword_field" placeholder="Example: keyword1, keyword2, keyword3"/>
                                            <em className="small text-muted">Separate multiple by comma.</em>
                                        </div>                                    
                                    </div>
                                    <div className="form-group mt-2">
                                        <label htmlFor="desc_field" className="col-form-label">Description:</label>
                                        <div className="">
                                            <textarea type="text" { ...register('description') } className="form-control" id="desc_field" placeholder="Write here..."></textarea>
                                            <em className="small text-muted">In a few words, explain what this site is about.</em>
                                        </div>
                                    </div>
                                    <div className="form-group mt-2 d-none">
                                        <label htmlFor="facebook_field" className="col-form-label">Facebook:</label>
                                        <div className="">
                                            <input type="text" { ...register('facebook') } className="form-control" id="facebook_field" placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="form-group mt-2 d-none">
                                        <label htmlFor="twitter_field" className="col-form-label">Instagram:</label>
                                        <div className="">
                                            <input type="text" { ...register('instagram') } className="form-control" id="twitter_field" placeholder="" />
                                        </div>
                                    </div>
                                    <div className="form-group mt-2 d-none">
                                        <label htmlFor="linkedin_field" className="col-form-label">LinkedIn:</label>
                                        <div className="">
                                            <input type="text" { ...register('linkedin') } className="form-control" id="linkedin_field" placeholder="" />
                                        </div>
                                    </div>
                                    <div className="form-group mt-2 d-none">
                                        <label htmlFor="twitter_field" className="col-form-label">Twitter:</label>
                                        <div className="">
                                            <input type="text" { ...register('twitter') } className="form-control" id="twitter_field" placeholder="" />
                                        </div>
                                    </div>
                                    <div className="form-group mt-2 d-none">
                                        <label htmlFor="instagram_field" className="col-form-label">Youtube:</label>
                                        <div className="">
                                            <input type="text" { ...register('youtube') } className="form-control" id="instagram_field" placeholder="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-12">

                                            <div className="col-md-12 mb-5">
                                                <div><h5 className="text-primary">Other Configurations</h5></div>
                                                <div className="form-group">
                                                    <label htmlFor="google_api_key" className="col-form-label">Google Maps API Key:</label>
                                                    <div className="">
                                                        <input type="text" { ...register('google_api_key',{ required: true }) } placeholder="Enter Google Map Api Key" className={errors.google_api_key && errors.google_api_key.type === "required" ? 'is-invalid form-control' : 'form-control' } />
                                                        <em className="small text-muted">Important for show address on Map.</em>
                                                    </div>
                                                </div>
                                            </div>

                                            <div><h5 className="text-primary">Mail Received Setting</h5></div>
                                            <div className="form-group">
                                                <label htmlFor="send_fsr_to" className="col-form-label">Receive New FSR Mail<strong className="text-danger"> *</strong>:</label>
                                                <div className="">
                                                    <input type="mail" { ...register('send_fsr_to',{ required: true }) } placeholder="Mail Address" className={errors.send_fsr_to && errors.send_fsr_to.type === "required" ? 'is-invalid form-control' : 'form-control' } />
                                                    <em className="small text-muted">Receive new generated fsr mail.</em>
                                                </div>
                                            </div>
                                            <div className="form-group mt-2">
                                                <label htmlFor="send_license_exp_to" className="col-form-label">Receive License Expiry Mail<strong className="text-danger"> *</strong>:</label>
                                                <div className="">
                                                    <input type="mail" { ...register('send_license_exp_to',{ required: true }) } placeholder="Mail Address" className={errors.send_license_exp_to && errors.send_license_exp_to.type === "required" ? 'is-invalid form-control' : 'form-control' } />
                                                    <em className="small text-muted">Receive reminder mails for license expiry 30 days before expiry date.</em>
                                                </div>
                                            </div>
                                            <div className="form-group mt-2 d-none d-sm-block1">
                                                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-12 mt-4">
                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
         }
        </>
    )
}
export default SiteSettings