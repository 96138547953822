import ReactDOM from 'react-dom';
import $ from 'jquery';
import React,{useContext, useState, useEffect} from 'react'; 
import { Spinner,Button, Tooltip, OverlayTrigger} from 'react-bootstrap';
import { Context } from '../../components/Context';
import { toastNotify, downloadFrsReport } from '../../components/Helper';
import Datatables, { redrawDataTable, reloadUrlDataTable } from '../../components/Tabel/Datatables';
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { deleteRequest }  from '../../components/Services/Api';
import { Link } from 'react-router-dom';

function Fsr() {
    window.document.title = "All FSR | I2R Portal";

    const [user] = useContext(Context);
    const [ reload, setReload ] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [id, setId] = useState(null);

    const [dt] = useState({
        dt_url: `fsr/all`,
        dt_name: 'fsr_list',
        dt_export : false,
        dt_column: [
            { data:"fsr_no", name:'fsr_no', title: "FSR No.", class:"text-nowrap"},
            { data:"customer", name:'customer', title:"Customer", className:"text-truncate maxw-250"},
            { data:"location", name:'location', title:"Site/Location", class:"text-truncate maxw-250"},
            { data:"address", name:'address', title:"Address", class:"text-truncate maxw-250"},
            { data:"tel", name:'tel',  title:"Tel", class:"text-nowrap" },
            { data:"status", name:'status', title:"Status", class:"fsr-types text-nowrap" },
            { data:"created_by", name:'authorised.name', title:"Created By", class:"text-nowrap" },
            { data:"created_at", name:'created_at', title:"Created At", class:"text-nowrap" },
            { data:"id", title:"Action", sortable:false, searchable:false, orderable: false, class:"text-nowrap text-center", width:160}
        ],
        dt_column_defs: [
            {
                targets: 8,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<>
                        <div className="text-nowrap">
                            { (user && user.allowed && user.allowed.includes('fsr-download')) &&
                                <OverlayTrigger placement="top" overlay={<Tooltip> Download FSR Report </Tooltip>}>
                                    <Button variant='warning' className="btn btn-sm btn-loading me-1" onClick={(e) => downloadFrsReport(e, rowData.id) }>
                                        <Spinner className="spinner-border spinner-border-sm text-right me-2 pe-none" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner> 
                                        <svg className="icon tscale-1p3 me-2 pe-none" role="img"><use href="#icon_download" /></svg>
                                        PDF
                                    </Button>
                                </OverlayTrigger>
                            } 
                            { (user && user.allowed && user.allowed.includes('fsr-edit') && rowData.types !== 1) ?
                                <OverlayTrigger placement="top" overlay={<Tooltip> Edit This FSR </Tooltip>}>
                                    <a href={`/admin/fsr/edit/${rowData.id}`} className="btn btn-sm btn-primary me-1">
                                        <svg className="icon tscale-1p1" role="img"><use href="#icon_edit" /></svg>
                                    </a>
                                </OverlayTrigger>
                                : 
                                <OverlayTrigger placement="top" overlay={<Tooltip> Can't Edit This FSR </Tooltip>}>
                                    <a className="btn btn-sm btn-primary me-1 opacity-50" disabled={true} >
                                        <svg className="icon tscale-1p1" role="img"><use href="#icon_edit" /></svg>
                                    </a>
                                </OverlayTrigger>
                            } 
                            { (user && user.allowed && user.allowed.includes('fsr-delete')) && 
                                <OverlayTrigger placement="top" overlay={<Tooltip>Delete this FSR</Tooltip>}>
                                    <Button variant="danger" className="btn btn-sm" onClick={() => handleDelete(rowData.id)}>
                                        <svg className="icon" role="img"><use href="#icon_trash" /></svg>
                                    </Button>
                                </OverlayTrigger>
                            } 
                        </div>                             
                    </>, td)
                },
            },
        ]
    });

    useEffect(() => {
        reloadUrlDataTable(dt, `fsr/all/${selectedOption}`);
        if(!$('.dt-custom-filter select').hasClass('types')){
            $('.dt-custom-filter').append(`
            <select class="types form-select form-select-sm w-auto d-inline-block mt-1 mt-sm-0 me-sm-3 float-end" onchange="SelectColumnFilter(this.value)" 
            value="all">
                <option value="all">All Status</option>
                <option value="0">Draft</option>
                <option value="1">Completed</option>
            </select>`);
        }
    },[reload]);
    
    async function handleDelete(id){
        setId(id);
        setDisplayConfirmationModal(true);
    }

    window.SelectColumnFilter = (option) => {
        setSelectedOption(option);
        reload == true ? setReload(false) : setReload(true);
    }
    
    // Handle the actual deletion of the item
    const submitDelete = (id) => {
        deleteRequest(`fsr/delete/${id}`,(responce)=> {
            setDisplayConfirmationModal(false);
            if(responce.success){
                toastNotify('success', responce.message);
                redrawDataTable(dt);
            }else{
                toastNotify('danger', responce.message);
            }
        }); 
    } 

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row clearfix">
                        <div className="col-md-10">
                            <h4 className="page-heading">All FSR</h4>
                        </div>
                        <div className="col-auto ms-auto col-md-2 mb-2 mt-2">
                            { (user && user.allowed && user.allowed.includes('fsr-create')) && 
                                <OverlayTrigger placement="left" overlay={<Tooltip> Click here to add new FSR </Tooltip>}>
                                    <Link to="../fsr/new" className="btn btn-primary px-3 text-nowrap float-end" >
                                        <svg className="icon tscale-1p3 me-1 pe-none" role="img"><use href="#icon_plus" /></svg> Add New FSR
                                    </Link>
                                </OverlayTrigger>
                            }
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body">
                                <Datatables dt_name="fsr_list" dataPageLength="15"/>
                                <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} id={id} message={'Are you sure you want to delete this record?'}  />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Fsr