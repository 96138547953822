import React, { memo, useContext, useState, useEffect } from "react";
import { Link, useParams} from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Context } from '../../components/Context';
import { getRequest}  from '../../components/Services/Api';
import SummaryStatus  from './Tabs/SummaryStatus';
import InstallationDetails  from './Tabs/InstallationDetails';
import InspectionFindings  from './Tabs/InspectionFindings';

function ManageSitePoint() {

    window.document.title = "Manage Site | I2R Portal";

    const [activeTab, setActiveTab] = useState('');
    const [user] = useContext(Context);
    const [ reload, setReload ] = useState(false);
    const { id } = useParams();
    const [site, setSite] = useState({});

    const currentTab = (tabs) => {

        switch(tabs) {
            case "Summary-Status":   
                return <SummaryStatus siteId={id} site={site} user={user} detailsAction={siteReload} />;
            case "Installation-Details":   
                return <InstallationDetails siteId={id} site={site} user={user} />;
            case "Inspection-Findings": 
                return <InspectionFindings siteId={id} site={site} user={user} />;
            default:
                return <SummaryStatus siteId={id} site={site} user={user} detailsAction={siteReload} />;
        }
    }

    useEffect(() => {
        let storeTab = localStorage.getItem('site-tab');
        getRequest(`site/get/${id}`, (response)=> {
            if(response.success){
                setSite(response.data);
            }
        });  
        if(storeTab){
            setActiveTab(storeTab);
        }else{
            if(user && user.allowed){
                if(user.allowed.includes('site-summary-view')){
                    setActiveTab('Summary-Status');
                }
                else if(user.allowed.includes('site-installation-view')){
                    setActiveTab('Installation-Details');
                }
                else if(user.allowed.includes('site-inspection-findings-view')){
                    setActiveTab('Inspection-Findings');
                }
            }
        }
        
    },[reload]);

    const siteActiveTab = (storeTab) => {
        localStorage.setItem('site-tab',storeTab);
        setActiveTab(storeTab);
    }

    const siteReload = (action = false) => {
        if(action === true){
            reload === false ? setReload(true) : setReload(false);
        }
    }

    return (
        <>
            <section className="admin-wrapper site-details">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between mb-4">
                        <div className="col">
                            <h4 className="page-heading">{ site && site.name ? site.name : 'N/A' }
                                { (user && user.allowed && user.allowed.includes('site-edit')) && 
                                    <OverlayTrigger placement="right" overlay={<Tooltip> Edit Site </Tooltip>}>
                                        <Link to={`../site/edit/${id}`} className="btn text-danger">
                                            <svg className="icon me-1 tscale-1p4" role="img">
                                                <use href="#icon_edit" />
                                            </svg>
                                        </Link>
                                    </OverlayTrigger>
                                }
                            </h4>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-sm-12">
                            <nav>
                               { (user && user.allowed) && 
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                   { user.allowed.includes('site-summary-view') && <button className={`nav-link text-blue fw-bolder ${activeTab==='Summary-Status'?' active':''}`} data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={() => siteActiveTab('Summary-Status')}>Summary Status</button> }
                                   { user.allowed.includes('site-installation-view') && <button className={`nav-link text-blue fw-bolder ${activeTab==='Installation-Details'?' active':''}`} data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => siteActiveTab('Installation-Details')}>Installation Details</button> }
                                   { user.allowed.includes('site-inspection-findings-view') && <button className={`nav-link text-blue fw-bolder ${activeTab==='Inspection-Findings'?' active':''}`} data-bs-toggle="tab"  type="button" role="tab" aria-controls="nav-contact" aria-selected="false" onClick={() => siteActiveTab('Inspection-Findings')}>Inspection Findings</button> }
                                </div>
                               }
                            </nav>
                            <div className="tab-content" id="nav-tabContent">
                               { (site && site.id) && <div>{ currentTab(activeTab) }</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default memo(ManageSitePoint)