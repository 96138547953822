import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router} from "react-router-dom";
import "bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './css/custom.css';

import Routing from './components/Routing';

import Icons from './components/Icons';
import  MetaData  from './components/MetaData';
import reportWebVitals from './reportWebVitals';
ReactDOM.render(
    <React.StrictMode>
        <MetaData />
        <Router>
            <Routing />
        </Router>
        <Icons />
    </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();