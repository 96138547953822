import $ from 'jquery';
import React,{ useState, useEffect, useContext } from 'react'; 
import { useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import UsersDropDown from '../../components/UsersDropDown';
import { Context } from '../../components/Context'
import { toastNotify } from '../../components/Helper';
import { getRequest, fetchData }  from '../../components/Services/Api';
import DatepickerComponent from '../../components/DatepickerComponent';

function EditSite() {
    window.document.title = "Edit Site | I2R Portal";
    
    const [tinyloader, setTinyloader] = useState(false);
    const form = useForm();
    const { register, handleSubmit, setValue, formState: { errors } } = form;
    const [ reload, setReload ] = useState(false);
    const { id } = useParams();
    const [user] = useContext(Context);
    const [dateExpiry, setDateExpiry] = useState(null);
    const [dateLast, setDateLast] = useState(null);
    const [dateNext, setDateNext] = useState(null);
    const [dateHtSRoom, setDateHtSRoom] = useState(null);
    const [dateTransRoom, setTransRoom] = useState(null);
    const [dateLtSRoom, setDateLtSRoom] = useState(null);
    const [defClient, setDefClient] = useState('');

    useEffect(() => {
        getRequest(`site/get/${id}`, (response)=> {
            if(response.success){

                const content = response.data;

                setDateExpiry(content.license_expiry);
                setDateLast(content.last_shutdown);
                setDateNext(content.next_shutdown);
                setDateHtSRoom(content.ht_servicing_date);
                setTransRoom(content.transformers_servicing_date);
                setDateLtSRoom(content.lt_servicing_date);
                setDefClient(content.client);

                setValue('name',content.name);
                setValue('customer_name',content.customer_name);
                setValue('license_no',content.license_no);
                setValue('address',content.address);
                setValue('postal_code',content.postal_code); 
                setValue('installation_type',content.installation_type);
                setValue('approved_load',content.approved_load);
                setValue('pg_intakes',content.pg_intakes);
                setValue('description',content.description);
                setValue('pic_name',content.pic_name);
                setValue('pic_contact_number',content.pic_contact_number);
                setValue('sld',content.sld);
                
                setReload(true);
            }
        });  
    },[reload]);

    const userChange = (option) => {
        setDefClient(option.current.value);
    }
 
    async function onSiteSubmit(data) {
       setTinyloader(true);
        try {
            fetchData(`site/update/${id}`, data, 'put', (responce)=> {
                if(responce.success){
                    toastNotify('success', responce.message);
                    setReload(true);
                }else{
                    if(responce.message === 'validation_error'){
                        [responce.data].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    }
                    else {
                        toastNotify('danger', responce.message)
                    }
                }
                setTinyloader(false);
            }); 
        } catch (err) {
            toastNotify('danger', 'Invalid action perform. Please try again later');
            setTinyloader(false);
        }
    }

    return(
        <>
         { (user && user.allowed && user.allowed.includes('site-edit')) &&
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between mb-4">
                        <div className="col">
                            <h4 className="page-heading">Edit Site</h4>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center">
                        <div className="col-sm-12">
                            <form className="p-5 bg-white rounded-25" id='siteform' name='siteform' onSubmit={handleSubmit(onSiteSubmit)} >
                                <div className="row">

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label htmlFor="sitename" className="form-label">Site Name<strong className="text-danger"> *</strong></label>
                                            <input type="text" name="name" {...register('name', { required: true })} id="sitename" placeholder="Site Name" className={errors.name && errors.name.type === "required" ? 'is-invalid form-control' : 'form-control' }/>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-12">
                                        <label htmlFor="customer" className="col-form-label">Customer Name</label>
                                        <div className="">
                                            <input type="text" {...register('customer_name', { required: false })} id="customer_name" placeholder="Enter Customer Name" className={errors.customer_name && errors.customer_name.type === "required" ? 'is-invalid form-control' : 'form-control' } spellCheck="false"/>
                                        </div> 
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label htmlFor="client" className="form-label">Site Owner</label>
                                            <UsersDropDown dropDownChange={userChange} defaultVal={defClient} name="client" form={form} required="false" errors={errors.client} /> 
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label htmlFor="license_no" className="form-label">Electrical Installation License No.<strong className="text-danger"> *</strong></label>
                                            <input type="text" name="license_no" {...register('license_no', { required: true })} id="license_no" placeholder="License No" className={errors.license_no && errors.license_no.type === "required" ? 'is-invalid form-control' : 'form-control' }/>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label htmlFor="license_expiry" className="form-label">License Expiry Date<strong className="text-danger"> *</strong></label>
                                            <DatepickerComponent type="date" defDate={dateExpiry} name="license_expiry" form={form} required="true" errors={errors.license_expiry} />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label htmlFor="address" className="form-label">Site Address<strong className="text-danger"> *</strong></label>
                                            <input type="text" name="address" {...register('address', { required: true })} id="address" placeholder="Site Full Address" className={errors.address && errors.address.type === "required" ? 'is-invalid form-control' : 'form-control' }/>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label htmlFor="postal_code" className="form-label">Postal Code</label>
                                            <input type="text" name="postal_code" {...register('postal_code')} id="postal_code" placeholder="Postal Code" className={errors.postal_code && errors.postal_code.type === "required" ? 'is-invalid form-control' : 'form-control' }/>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label htmlFor="last_shutdown" className="form-label">Date of Last Servicing</label>
                                            <DatepickerComponent type="date" defDate={dateLast} maxDate={new Date()} name="last_shutdown" form={form} required="false" errors={errors.last_shutdown} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label htmlFor="next_shutdown" className="form-label">Due date for Next Servicing</label>
                                            <DatepickerComponent type="date" defDate={dateNext} name="next_shutdown" form={form} required="false" errors={errors.next_shutdown} />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label htmlFor="installation_type" className="form-label">Intake Voltage<strong className="text-danger"> *</strong></label>
                                            <select name="installation_type" {...register('installation_type', { required: true })} id="installation_type" placeholder="Eg. 400V | 22kV" className={errors.installation_type && errors.installation_type.type === "required" ? 'is-invalid form-select custom-select-sm' : 'form-select custom-select-sm' }>
                                                <option value='22kV'>22kV</option>
                                                <option value='400V'>400V</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label htmlFor="pg_intakes" className="form-label">No. of PG Intakes</label>
                                            <input type="number" name="pg_intakes" {...register('pg_intakes')} id="pg_intakes" placeholder="No. of PG Intakes" className='form-control' maxLength={5} />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label htmlFor="pg_intakes" className="form-label">Approved Load</label>
                                            <input type="number" name="approved_load" {...register('approved_load',{ required: false })} id="approved_load" placeholder="eg. 500kV" className={errors.approved_load && errors.approved_load.type === "required" ? 'is-invalid form-control' : 'form-control' } maxLength={5} />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label htmlFor="pic_name" className="form-label">PIC Name</label>
                                            <input type="text" {...register('pic_name')} placeholder="PIC Name" className={errors.pic_name && errors.pic_name.type === "required" ? 'is-invalid form-control' : 'form-control' } maxLength={64} />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label htmlFor="pic_contact_number" className="form-label">PIC Contact Number</label>
                                            <input type="text" {...register('pic_contact_number')} placeholder="PIC Contact Number" className={errors.pic_contact_number && errors.pic_contact_number.type === "required" ? 'is-invalid form-control' : 'form-control' } maxLength={32} />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label htmlFor="sld" className="form-label">SLD</label>
                                            <input type="text"  {...register('sld')} placeholder="SLD" className={errors.sld && errors.sld.type === "required" ? 'is-invalid form-control' : 'form-control' } />
                                        </div>
                                    </div>

                                    <div className="col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="description" className="form-label">Description</label>
                                            <textarea name="description" {...register('description')} rows="3" placeholder='Description' className={errors.description && errors.description.type === "required" ? 'is-invalid form-control' : 'form-control' }></textarea>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 border pb-4">
                                        <div className="row align-items-center justify-content-center">
                                            <div className='col-sm-12 mb-2 p-2 bg-light-active bg-gradient h6 text-blue m-0'>Servicing Information &#8667; Servicing Date</div>
                                            <div className="col-md-4 col-sm-12">
                                                <label htmlFor="ht_servicing_date" className="form-label"> HT Switch Room</label>
                                                <DatepickerComponent type="date" defDate={dateHtSRoom} name="ht_servicing_date" form={form} required="false" errors={errors.ht_servicing_date} />
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <label htmlFor="transformers_servicing_date" className="form-label"> Transformer Room</label>
                                                <DatepickerComponent type="date" defDate={dateTransRoom} name="transformers_servicing_date" form={form} required="false" errors={errors.transformers_servicing_date} />
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <label htmlFor="lt_servicing_date" className="form-label"> LT Switch Room</label>
                                                <DatepickerComponent type="date" defDate={dateLtSRoom} name="lt_servicing_date" form={form} required="false" errors={errors.lt_servicing_date} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="mb-3 mt-3">
                                    <button type="submit" className="btn btn-primary rounded-0" disabled={tinyloader}>
                                        { tinyloader && <span className="spinner-border spinner-border-sm mr-1"></span> } Update 
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div> 
                </div>
            </section>
         }
        </>
    )
}
export default EditSite