import React, { useContext, useState, useEffect} from "react";
import { Tooltip, OverlayTrigger} from 'react-bootstrap';
import SignaturePad from "signature_pad";
import { useForm } from 'react-hook-form';
import { Context } from '../../components/Context';
import { toastNotify, blobToBase64, noAvatarHandle, blankImage } from "../../components/Helper";
import {fetchData}  from '../../components/Services/Api';

let signaturePad = "";
const assetURL = process.env.REACT_APP_ASSET_URL;

function SiteSettings() {

    window.document.title = "My Profile | I2R Portal";

    const [tinyloader, setTinyloader] = useState(false);
    const { handleSubmit, register, setValue } = useForm();
    const [ reload, setReload ] = useState(false);

    const [ avatar, setAvatar ] = useState(`${assetURL}assets/images/avatar/user.jpg`);
    const [ signature, setSignature ] = useState(`${assetURL}signatures/signature.png`);

    const [user, setUser] = useContext(Context);

    useEffect(() => {  

        userSignature('draw');
        
        setValue("name", user.name);

        if(user.profile_image){
            setAvatar(`${assetURL}assets/images/avatar/${user.profile_image}`);
        }
        if(user.signature){
            setSignature(`${assetURL}signatures/${user.signature}`);
        }
    
    }, [reload])

    const handleImageUpload = async (e) => {
        const [file] = e.target.files;
        if (file) {
            let imgb64 = await blobToBase64(file);
            setAvatar(imgb64);
            setValue("profile_image", imgb64);
        }
    };

    async function onProfileSubmit(data) {
        setTinyloader(true);

        let formData = {
            id: user.id, 
            name: data.name
        };
        if(!signaturePad.isEmpty()){
            formData.signature = signaturePad.toDataURL("image/png")
            setSignature(formData.signature);
        }
        if(data.profile_image){
            formData.profile_image = data.profile_image
        }

        fetchData('users/update-profile', formData, 'post', (responce)=> {

            if(responce.success){

                let notifyType = 'danger';
                setTinyloader(false);

                if(responce.success){

                    notifyType = 'success';
                    setUser(previousState => ({ 
                        ...previousState, 
                        name       : responce.data.name,
                        signature  : responce.data.signature,
                        profile_image  : responce.data.profile_image
                    }));

                    document.getElementById("profile_image").src = avatar;
                    document.getElementById("header_profile_name").innerHTML = responce.data.name;

                    let userProfile = user;
                    userProfile.name = responce.data.name;
                    userProfile.profile_image = responce.data.profile_image;
                    userProfile.signature = responce.data.signature;
                    localStorage.setItem('i2r-user', JSON.stringify(userProfile));
                }
                toastNotify(notifyType, responce.message);
            }else{
                if(responce.message === 'validation_error'){
                    [responce.data].forEach((ele) => {
                        Object.keys(ele).map((v, i) => {
                            return toastNotify('danger', ele[v])
                        });
                    })
                }
                else {
                    toastNotify('danger', responce.message)
                }
            }
        }); 
    };

    const userSignature = (type) => {
        if(type==='draw'){
            const canvas = document.getElementById('your_signature');
            canvas.width = canvas.parentElement.clientWidth;
            canvas.height = canvas.parentElement.clientHeight;
            signaturePad = new SignaturePad(canvas, {
                backgroundColor: 'rgb(255, 255, 255)',
                penColor: 'rgb(0, 0, 0)'
            }); 
        }
        if (type==='clear') {
            signaturePad.clear()  
        }
    }

    return(
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between mb-4">
                        <div className="col">
                            <h4 className="page-heading">My Profile</h4>
                        </div>
                    </div>
                    <form className="row align-items-center justify-content-center p-4 bg-white" onSubmit={handleSubmit(onProfileSubmit)} id='myfrom'>
                        <div className="col-sm-12">
                            <div className="row mb-3 mb-md-4">
                                <label className="col-md-3 col-lg-2 col-form-label text-md-end">Profile Image:</label>
                                <div className="col-md-5">
                                    <div className="position-relative overlow-hidden">
                                        <input className="d-none" name="profile_image" {...register('profile_image')} id='avatar' type="file" accept="image/*" onChange={handleImageUpload} multiple={false} title="Upload Profile Image" />
                                        <OverlayTrigger placement="top" overlay={<Tooltip> Click here to Upload Profile Image </Tooltip>}>
                                            <label htmlFor="avatar" role="button"
                                                style={{ 
                                                    height: "80px", 
                                                    width: "80px", 
                                                    border: "1px dashed black" 
                                                    }} 
                                                >
                                                <img id="priv_profile_image" src={avatar} onError={noAvatarHandle} className="w-100 h-100" alt="upload profile image"/>
                                            </label>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3 mb-md-4">
                                <label htmlFor="name_field" className="col-md-3 col-lg-2 col-form-label text-md-end">Name:</label>
                                <div className="col-md-5">
                                    <input type="text" className="form-control" id="name_field" name="name" {...register('name')} placeholder="Name" defaultValue={user.name} spellCheck="false"/>
                                </div>
                            </div>
                            <div className="row mb-3 mb-md-4">
                                <label htmlFor="keyword_field" className="col-md-3 col-lg-2 col-form-label text-md-end">Your Signature:</label>
                                <div className="col-md-5">
                                    <div className="sign-canvas shadow">
                                        <canvas id="your_signature" width={601} height={200}></canvas>
                                    </div>
                                    <div className="d-flex pt-1">
                                        <em className="small pt-1">Sign Above</em>
                                        <button type="button" className="ms-auto btn btn-sm btn-outline-danger px-3 py-0 my-2" title="Clear Sign" onClick={()=>userSignature('clear')}>
                                            Clear
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-5 offset-md-3 offset-lg-0">
                                    { signature ? 
                                            <img className="w-100 maxh-200 border" id="signature_image" src={signature} onError={blankImage} />
                                        :''
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="offset-md-3 offset-xxl-2 col-md-9 col-xxl-10">
                                    <hr className="d-block d-sm-none" />
                                    <button type="submit" className="btn btn-primary rounded-0" disabled={tinyloader}>
                                        { tinyloader && <span className="spinner-border spinner-border-sm mr-1"></span> } Update 
                                    </button> 
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}
export default SiteSettings