import $ from 'jquery';
import jsZip from 'jszip';
import '../../css/datatable-custom.css';
import 'pdfmake';
import 'datatables.net';
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.colVis.min';
import 'datatables.net-buttons/js/buttons.flash.min';
import 'datatables.net-buttons/js/buttons.print.min';
import 'datatables.net-buttons/js/buttons.html5.min';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.JSZip = jsZip;

const API_URL = process.env.REACT_APP_API_URL

export const initDataTable = (dt) => {
    const exportButons = [{
            extend: "collection",
            className: 'btn btn-sm me-0 float-end export-btn border rounded',
            text: "Table Controls &nbsp;",
            buttons: [
                {
                    extend: "excelHtml5",
                    className: 'export-btn',
                    filename: 'sites',
                    exportOptions: {
                        columns: "thead th:not(.noExport)",
                        modifier: {
                            page: '-1' // -1 | all
                        }
                    }
                },
                {
                    extend: "csvHtml5",
                    className: 'export-btn',
                    filename: 'sites',
                    exportOptions: {
                        columns: "thead th:not(.noExport)",
                        modifier: {
                            page: '-1' // -1 | all
                        }
                    }
                },
                {
                    extend: 'pdfHtml5',
                    filename: 'sites',
                    orientation: 'landscape',
                    pageSize: 'LEGAL',
                    className: 'export-btn',
                    customize: function(doc) {
                        doc.defaultStyle.fontSize = 13;
                        doc.styles.tableHeader.fontSize = 13;
                        var count = table.columns(':visible').count();
                        if(count < 9){
                        doc.pageSize = 'LEGAL'; 
                        }else if(count >= 9 && count <=10){
                        doc.pageSize = 'A4'; 
                        }else if(count >= 11 && count <= 15){
                        doc.pageSize = 'A3';
                        }else if(count >= 16 && count <= 20){
                        doc.pageSize = 'A2';
                        }else if(count >= 21 && count <= 30){
                        doc.pageSize = 'A1';
                        }else{
                        doc.pageSize = 'A0';
                        }
                    }, 
                    exportOptions: {
                        columns: "thead th:not(.noExport)",
                        modifier: {
                            search: 'applied',
                            order: 'applied'
                        }
                    }
                },
                {
                    extend: 'print',
                    text: 'Print',
                    className: 'export-btn',
                    key: {
                        key: 'p',
                        altKey: true
                    },
                    exportOptions: {
                        columns: "thead th:not(.noExport)",
                    }
                },
                {
                    extend: 'copy',
                    text: 'Copy',
                    className: 'export-btn',
                    key: {
                        key: 'c',
                        altKey: true
                    },
                    exportOptions: {
                        columns: "thead th:not(.noExport)",
                    }
                },
                {
                    extend: "colvis",
                    text: 'Column Visibility &nbsp;',
                    collectionLayout: "fixed two-column",
                    collectionTitle: "Select Columns to Display",
                    postfixButtons: ["colvisRestore"],
                    columnText: function(dt, idx, title) {
                        return idx + 1 + ": " + title;
                    }
                }
            ]
        }
    ];
    const table = $('#wt_datatable_'+dt.dt_name).DataTable({
        dom: "B<'row clearfix mx-0'<'col-6 col-lg-2 px-0 text-center text-sm-start float-start'l><'dt-custom-filter col-6 col-lg-8 px-0 text-center text-sm-end exportbuttons'><'col-md-12 col-lg-2 px-0 ps-3 ps-ms-0 text-center text-lg-end'f>>" +
            "<'row clearfix mx-0'<'col-sm-12 px-0'<'table-responsive position-relative my-2' tr>>>" +
             "<'row clearfix mx-0'<'col-md-12 col-lg-5 px-0 text-center text-lg-start'i><'col-md-12 col-lg-7 px-0 text-center text-lg-end'p>>",
        // dom: 'Bfrtip',
        language: {
            searchPlaceholder: "Search..."
        },
        lengthMenu: [15, 25, 50, 75, 100],
        processing: true,
        serverSide: true,
        orderable: true,
        paging: dt.dt_paging,
        ajax: {
            url: API_URL+dt.dt_url,
            headers: {
                "Authorization": 'Bearer ' + localStorage.getItem('i2r-token')
            }
        },
        columns: dt.dt_column,
        columnDefs: dt.dt_column_defs, 
        order: dt.dt_order ? dt.dt_order : [[ 0, 'desc' ]],
        initComplete: dt.dt_filter,
        createdRow: dt.dt_createdRow,
        buttons: dt.dt_export ? exportButons: [],
    });
    table.buttons().container().appendTo($('.exportbuttons'));

    var headers = table.columns().header().toArray();
    $(headers).on('click', function (e) {
        table.search('').draw();
    });
}
    

export const reloadDataTable = (dt) => {
    if ($.fn.DataTable.isDataTable('#wt_datatable_'+dt.dt_name)) {
        $('#wt_datatable_'+dt.dt_name).DataTable().clear().draw(null, false)
    }else{
        initDataTable(dt)
    }
}

export const redrawDataTable = (dt) => {
    if ($.fn.DataTable.isDataTable('#wt_datatable_'+dt.dt_name)) {
        $('#wt_datatable_'+dt.dt_name).DataTable().clear().draw(null, false);
    }else{
        initDataTable(dt);
    }
}

export const reloadUrlDataTable = (dt, url) => {
    if ($.fn.DataTable.isDataTable('#wt_datatable_'+dt.dt_name) && url) {
        $('#wt_datatable_'+dt.dt_name).DataTable().clear().ajax.url(API_URL+url).load()
    }else{
        initDataTable(dt)
    }
}

function DataTables(props) {
    return (
        <>
        <table id={`wt_datatable_${props.dt_name}`} data-page-length={props.dataPageLength} className="table table-hover align-middle w-100">
            <thead className="bg-light"></thead>
        </table>            
        </>
    )
}

export default DataTables