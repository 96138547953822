import ReactDOM from 'react-dom';
import React,{useContext, useState, useEffect} from 'react'; 
import { useNavigate } from "react-router-dom";
import { Button,Tooltip, OverlayTrigger} from 'react-bootstrap';
import { Context } from '../../components/Context';
import { toastNotify, downloadFile } from '../../components/Helper';
import Datatables, { redrawDataTable } from '../../components/Tabel/Datatables';
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { deleteRequest }  from '../../components/Services/Api';

function AllReports() {
    window.document.title = "Tests Report | I2R Portal";
    const navigate = useNavigate();

    const [user] = useContext(Context);
    const [ reload, setReload ] = useState(false);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [id, setId] = useState(null);

    const [dt] = useState({
        dt_url: `reports/all`,
        dt_name: 'tests_report_list',
        dt_export : false,
        dt_column: [
            { data:"reports.id", name:'reports.id', title: "#ID"},
            { data:"name", name:'name', title:"Test Details"},
            { data:"site.name", name:'site.name', title:"Site Details", class:"text-nowrap text-truncate",
                render: function( data, type, rowData, meta ) {
                    // console.log(data)
                    if(data !== undefined){
                        return `
                        <div role="button" onclick="siteSummeryRegirect(${rowData.site.id})">
                            <span class='d-block text-secondary'>${rowData.site.name}</span>
                            <span class='d-block text-mutted'>${rowData.site.license_no}</span>
                        </div>`;
                    }
                    return ''
                }
            },
            { data:"reports.file", name:'reports.file',  title:"Report", sortable:false, searchable:false, orderable: false, "visible": (user && user.allowed && user.allowed.includes('report-tests-download')) ? true : false},
            { data:"reports.status", name:'reports.status',  title:"Status"},
            { data:"reports.created_at", name:'reports.created_at',  title:"Uploaded on", class:"text-nowrap text-truncate"},
            { data:"id", title:"Action", sortable:false, searchable:false, orderable: false, class:"text-nowrap text-center noExport", width:160, "visible": (user && user.allowed && (user.allowed.includes('report-tests-download') || user.allowed.includes('report-tests-delete'))) ? true : false}
        ],
        dt_column_defs: [
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<>
                        {(rowData.reports && rowData.reports.file) && 
                            <OverlayTrigger placement="top" overlay={<Tooltip> Download tests report </Tooltip>}><button className='btn btn-link btn-sm' key={rowData.reports.id} onClick={() => downloadFile(rowData.reports.file,'report_files')}>{rowData.reports.file}</button></OverlayTrigger>
                        }                            
                    </>, td)
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div key={rowData.reports.id}>
                        {
                            (rowData.reports && rowData.reports.id && rowData.reports.status === 0) 
                            ? <span>No anomalies found</span>
                            :
                            <div>
                               <span className='d-block text-secondary'>Anomalies found</span>
                               <span className='d-block text-mutted'>{rowData.reports.anomalies}</span>
                            </div> 
                        } 
                        </div>                       
                    , td)
                }
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<>
                        <div className="text-nowrap d-flex justify-content">
                        { (user && user.allowed && user.allowed.includes('report-tests-delete')) && 
                            <OverlayTrigger placement="top" overlay={<Tooltip> Delete tests record </Tooltip>}>
                                <Button variant='danger' type="button" className="btn btn-sm me-1 d-flex justify-content-end" onClick={() => handleDelete(rowData.id)}>
                                    <svg className="icon" role="img"><use href="#icon_trash" /></svg>
                                </Button>
                            </OverlayTrigger>
                        }
                        { (user && user.allowed && user.allowed.includes('report-tests-download')) &&
                            <>
                            { ( rowData.reports && rowData.reports.file ) && <OverlayTrigger placement="top" overlay={<Tooltip> Download tests report </Tooltip>}>
                                    <Button variant='warning' type="button" className='btn btn-sm btn-loading d-flex justify-content-start' onClick={() => downloadFile(rowData.reports.file, 'report_files')}>
                                        <svg className="icon tscale-1p5 pe-none" role="img"><use href="#icon_download" /></svg>
                                    </Button></OverlayTrigger>
                            }
                            </>
                        }
                    </div>                            
                    </>, td)
                }
            }
        ]
    });

    useEffect(() => {
        redrawDataTable(dt);
    },[reload, dt]);
    
    async function handleDelete(id){
        setId(id);
        setDisplayConfirmationModal(true);
    }
    // Handle the actual deletion of the item
    const submitDelete = (id) => {
        deleteRequest(`reports/delete/${id}`,(responce)=> {
            setDisplayConfirmationModal(false);
            if(responce.success){
                toastNotify('success', responce.message);
            }else{
                toastNotify('danger', responce.message);
            }
            reload ? setReload(false) : setReload(true);
        }); 
    } 

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    window.siteSummeryRegirect = (site_id) => {
        localStorage.setItem('site-tab','Summary-Status');
        navigate(`../site/view/${site_id}`)
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between mb-4">
                        <div className="col">
                            <h4 className="page-heading">Tests Report</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <Datatables dt_name="tests_report_list" dataPageLength="15"/>
                                    <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} id={id} message={'Are you sure you want to delete this record?'}  />
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </section>
        </>
    )
}
export default AllReports