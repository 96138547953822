import React, {useContext, useState, useEffect} from "react";
import { Form, Button} from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { Context } from '../../components/Context';
import { toastNotify } from "../../components/Helper";
import {getRequest, fetchData}  from '../../components/Services/Api';

function Permissions() {
    window.document.title = "Permissions | I2R Portal";

    const form = useForm();
    const { register, handleSubmit, setValue } = form;
    const { register: register2 } = useForm();
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ isUpdating, setIsUpdating ] = useState(false);
    const [ role, setRole ] = useState(2);
    const [ roles, setRoles ] = useState({});
    const [ userRole, setUserRole ] = useState(2);
    const [ permissions, setPermissions ] = useState({});
    const [user, setUser] = useContext(Context);

    const  privilegesArr = [];

    useEffect(() => {
        getRoleBasePermissions(role);
    }, [role]);

    const handleChange = (role_id) => {
        setUserRole(role_id);
    }

    const handleRoles = (e) => {
        let formData = new FormData(document.getElementById('roleForm'));
        setRole(formData.get('role'));
        setIsUpdating(true);
        setTimeout(() => setIsUpdating(false), 500)
    }

    async function getRoleBasePermissions(role){
        getRequest(`permission/get/${role}`, (response)=> {
            if(response.success){

                setRoles(response.data.roles);
                setPermissions(response.data.permissions);

                setValue('permission[]','');

                if((response.data.rolePermissions && response.data.rolePermissions.length>0)){
                    let result = response.data.rolePermissions.map(i=>String(i));
                    setValue('permission[]',result);
                }
            }else{
                if(response.message === 'validation_error'){
                    [response.data].forEach((ele) => {
                        Object.keys(ele).map((v, i) => {
                            return toastNotify('danger', ele[v])
                        });
                    })
                }
                else {
                    toastNotify('danger', response.message)
                }
            }
        });
    }

    async function onPermissionSubmit(formData) {
        setIsSubmitting(true);
        try {
            fetchData(`permission/update/${role}`, formData, 'post', (responce)=> {
                setIsSubmitting(false);
                if(responce.success){
                    toastNotify('success', responce.message);

                    if(user.type === role){ 

                        setUser(previousState => ({ 
                            ...previousState, 
                            allowed : responce.data.allowed
                        }));

                        let userProfile = user;
                        userProfile.allowed = responce.data.allowed;
                        localStorage.setItem('i2r-user', JSON.stringify(user));
                    }
                }else{
                    if(responce.message === 'validation_error'){
                        [responce.data].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    }
                    else {
                        toastNotify('danger', responce.message);
                    }
                }
            }); 
        } catch (err) {
            toastNotify('danger', 'Invalid action perform. Please try again later');
            setIsSubmitting(false);
        }
    }
   
    return (
        <>
          { ( user.type && Number(user.type) === 1) &&
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between mb-4">
                        <div className="col">
                            <h4 className="page-heading">Permissions</h4>
                        </div>
                    </div>
                    <div className="row g-1 clearfix">
                        <div className="col-md-3 col-sm-12">
                            <Form id='roleForm' name='roleForm' method="post" >
                                <div className="input-group mb-3 S">
                                    <Form.Select {...register2('role')} value={userRole} name="role" className="form-select rounded-0 text-uppercase1" id='role' placeholder="Select Role..." aria-describedby="button-addon2" onChange={(e) =>handleChange(e.target.value)}>
                                        { (roles && roles.length>0) && roles.map((userrole, ind) =>
                                            <option key={ind } value={ userrole.id }>{ userrole.name }</option>
                                            )
                                        };
                                    </Form.Select>
                                    <Button variant="primary" type="button" onClick={(e) => handleRoles(e) } className="px-md-3" id="button-addon2">
                                     {isUpdating && <span className="spinner-border spinner-border-sm sm-2 me-2"></span>} Update
                                    </Button>
                                </div>
                            </Form>
                        </div>
                        { (role && Number(role) === 1) ?
                            <div className="col-md-9 col-sm-12">
                                <div className="ms-auto float-end">
                                    <div className="alert alert-danger" role="alert">
                                        Super administrator permissions can't change
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="col-md-9 col-sm-12 d-none d-md-block">
                                <div className="ms-auto float-end">
                                    <Button variant="primary" type="submit" form="permissionForm" className="ms-auto float-end px-md-3" disabled={isSubmitting}>
                                        {isSubmitting && <span className="spinner-border spinner-border-sm sm-2 me-2"></span>}
                                            Save Changes
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>
                    <Form id='permissionForm' name='permissionForm' onSubmit={handleSubmit(onPermissionSubmit)} >

                        <div className="row mt-3 bg-white1 p-41 p-sm11-5 permission-block" data-roleid={role}>
                            { (permissions && permissions.length>0) && permissions.map((permission, ind) => {

                                var privileges = permission.name && permission.name.replace(/-/g, ' ');
                                var privileges_names = permission.name && permission.name.split('-');

                                if(userRole && Number(userRole) === 3 && (privileges_names[0] === 'staff' || privileges_names[0] === 'setting' || privileges_names[0] === 'permission') ){
                                    return <div key={ind}></div>;
                                }else if(userRole && Number(userRole) === 2 && (privileges_names[0] === 'permission' || privileges_names[0] === 'setting')){
                                    return <div key={ind}></div>;
                                }else if(userRole && Number(userRole) === 1 && (privileges_names[0] === 'permission')){
                                    return <div key={ind}></div>;
                                }else{

                                    if(!privilegesArr.includes(privileges_names[0])){

                                        privilegesArr.push(privileges_names[0]);

                                        return <React.Fragment key={ind}>
                                            <div className="col-sm-12">
                                                <h5 className="shadow-sm mb-2 p-3 bg-light-active bg-gradient mt-4 h6 text-blue m-0 text-uppercase">
                                                    <svg className="icon me-1" title=""><use href="#icon_edit" /></svg> 
                                                    { privileges_names[0] }
                                                </h5>
                                            </div>
                                            <div  className="col-sm-4 col-md-2">
                                                <div className="shadow-sm p-4 mb-2 rounded bg-light bg-gradient">
                                                    <div className="form-check form-check-inline">
                                                        <Form.Label className="form-check-label text-capitalize" htmlFor={`permission${ind}`}>
                                                            { privileges.replace(`${privileges_names[0]} `, '') }
                                                        </Form.Label>
                                                        <Form.Check {...register('permission[]')} value={permission.id} id={`permission${ind}`} type="switch" />
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }

                                    return <div key={ind} className="col-sm-4 col-md-2">
                                        <div className="shadow-sm p-4 mb-2 rounded bg-light bg-gradient">
                                            <div className="form-check form-check-inline">
                                                <Form.Label className="form-check-label text-capitalize" htmlFor={`permission${ind}`}>
                                                    { privileges.replace(`${privileges_names[0]} `, '') }
                                                </Form.Label>
                                                <Form.Check {...register('permission[]')} value={permission.id} id={`permission${ind}`} type="switch" />
                                            </div>
                                        </div>
                                    </div>
                                }
                              }
                            )}
                        </div>

                        <div className="row align-items-center mt-2">
                            <div className="col-sm-12">
                                    { (role && Number(role) !== 1 ) && <Button variant="primary" type="submit" className="px-5" disabled={isSubmitting}>
                                        {isSubmitting && <span className="spinner-border spinner-border-sm sm-2 me-2"></span>}
                                            Submit
                                        </Button>
                                    }
                                </div>
                            </div>
                    </Form>
                </div>
            </section>
          }
        </>
    )
}
export default Permissions