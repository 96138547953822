import React, { memo, useContext, useState } from "react";
import { useForm } from 'react-hook-form';
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { format } from 'date-fns';
import { Context } from '../../../components/Context';
import { toastNotify} from '../../../components/Helper';
import DatepickerComponent from '../../../components/DatepickerComponent';
import { postFileData }  from '../../../components/Services/Api';
import SiteDetails  from './SiteDetails';

function SummaryStatus(props) {

    window.document.title = "Summary Status";
    const [user] = useContext(Context);

    const form = useForm();
    const { register, handleSubmit, setValue, formState: { errors, isSubmitting } } = form;
    const [displayModal, setDisplayModal] = useState(false);
    const [displayShuddownModal, setDisplayShuddownModal] = useState(false);

    const site_id = props.siteId ? props.siteId : 0;
    const { id } = useParams();

    const lastInspection = (props.site.last_inspection && props.site.last_inspection !== '0000-00-00') ? props.site.last_inspection : null;
    const nextInspection = (props.site.next_inspection && props.site.next_inspection !== '0000-00-00') ? props.site.next_inspection : null;
    const lastShutdown   = (props.site.last_shutdown && props.site.last_shutdown !== '0000-00-00') ? props.site.last_shutdown : null;
    const nextShutdown   = (props.site.next_shutdown && props.site.next_shutdown !== '0000-00-00') ? props.site.next_shutdown : null;

    const next_inspection_flag = props.site.next_inspection_flag ? props.site.next_inspection_flag : 0;
    const next_shutdown_flag   = props.site.next_shutdown_flag ? props.site.next_shutdown_flag : 0;


    async function onInspSubmit(data) {
        let formData = new FormData(document.getElementById('inspectionFindingsform'));
        let tabname = data.tabname ? data.tabname : '';
        saveFormData(formData, tabname);
    }

    async function saveFormData(data, tabname = '') {
        try {
            postFileData(`site/manage/${site_id}/${tabname}`, data, 'post', (responce)=> {
                if(responce.success){
                    toastNotify('success', responce.message);
                    props.detailsAction(true);
                    hideConfirmationModal();
                }else{
                    if(responce.message === 'validation_error'){
                        [responce.data].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    }
                    else {
                        toastNotify('danger', responce.message)
                    }
                }
            }); 
        } catch (err) {
            toastNotify('danger', 'Invalid action perform. Please try again later');
        }
    };

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayModal(false);
        setDisplayShuddownModal(false);
    };

    return (
        <>
            <div className="tab-pane fade show active" role="tabpanel">
                <div className="table-responsive">
                    <table className="table-common">
                        <tbody>
                            <tr>
                                <td>Inspection</td>
                                <td>
                                    Last Inspection Date:
                                    <span className="d-block fw-bold">
                                        { lastInspection ? format(new Date(lastInspection), 'dd/MM/yyyy') : 'N/A' }
                                    </span>
                                    
                                </td>
                                <td>
                                    Next Inspection Due:
                                    <span className="d-block fw-bold">
                                        { nextInspection ? format(new Date(nextInspection), 'dd/MM/yyyy') : 'N/A' }
                                    </span>
                                </td>
                                <td className="text-nowrap">
                                    { (user && user.allowed && user.allowed.includes('site-inspection-edit')) && 
                                        <button className="btn btn-sm btn-primary me-2" onClick={() => {setDisplayModal(true); setValue('tabname', 'edit_inspection') } }>
                                            <svg className="icon me-2" role="img"><use href="#icon_edit" /></svg>
                                            Edit
                                        </button>
                                    }
                                </td>
                                <td>
                                    { next_inspection_flag === 0 
                                        ? 
                                        <svg className="icon me-2 tscale-1p4 text-success" role="img"><use href="#icon_tick" /></svg>
                                        : 
                                        <svg className="icon me-2 tscale-1p4 text-danger" role="img"><use href="#icon_danger" /></svg>
                                    }
                                </td>
                            </tr>

                            <tr>
                                <td>Shutdown Servicing</td>
                                <td>
                                    Last Servicing Date:
                                    <span className="d-block fw-bold">
                                    { lastShutdown ? format(new Date(lastShutdown), 'dd/MM/yyyy') : 'N/A' }
                                    </span>
                                    
                                </td>
                                <td>
                                    Next Servicing Due:
                                    <span className="d-block fw-bold">
                                        { nextShutdown ? format(new Date(nextShutdown), 'dd/MM/yyyy') : 'N/A' }
                                    </span>
                                </td>
                                <td className="text-nowrap">
                                    { (user && user.allowed && user.allowed.includes('site-edit')) && 
                                        <button className="btn btn-sm btn-primary me-2" onClick={() => {setDisplayShuddownModal(true); setValue('tabname', 'edit_shutdown') }}>
                                            <svg className="icon me-2" role="img"><use href="#icon_edit" /></svg>
                                            Edit
                                        </button>
                                    }
                                </td>
                                <td>
                                    { next_shutdown_flag === 0 
                                        ? 
                                        <svg className="icon me-2 tscale-1p4 text-success" role="img"><use href="#icon_tick" /></svg>
                                        : 
                                        <svg className="icon me-2 tscale-1p4 text-danger" role="img"><use href="#icon_danger" /></svg>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="row my-4">
                    <div className="col-sm-12">
                        <div className="bg-white shadow-sm">
                            { (props.site && props.site.id) && <SiteDetails siteId={id} site={props.site} user={user} /> }
                        </div>
                    </div>
                </div>
            </div>

            { displayModal && <Modal show={displayModal} onHide={hideConfirmationModal} className="modal" id="add_new_inspection">
                <div className="modal-header">
                    <h5 className="modal-title" id="addnewclientsLabel">Edit Inspection</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                </div>
                <div className="modal-body">
                    <form id='inspectionFindingsform' name='inspectionFindingsform' onSubmit={handleSubmit(onInspSubmit)}>
                        <input type="hidden" name="tabname" { ...register('tabname') } value='edit_inspection' />
                        <input type="hidden" name="site_id" { ...register('site_id') } value={site_id} />
                        <div className="row mb-1 justify-content-center">
                            <label htmlFor="date" className="col-form-label">Date of Last Inspection<strong className="text-danger"> *</strong></label>
                            <div className="">
                                <DatepickerComponent type="date" name="last_inspection" maxDate={new Date()}  defVal={lastInspection} form={form} required="true" errors={errors.last_inspection} /> 
                            </div>
                        </div>
                        <div className="row mb-1 justify-content-center">
                            <label htmlFor="date" className="col-form-label">Due date for Next Inspection<strong className="text-danger"> *</strong></label>
                            <div className="">
                                <DatepickerComponent type="date" name="next_inspection" defVal={nextInspection} form={form} required="true" errors={errors.next_inspection} /> 
                            </div>
                        </div>

                        <div className="row mb-1 justify-content-center mt-3">
                            <div className="col-md-12">
                                <div className="text-end">
                                    <button type="submit" className="btn btn-primary px-4" disabled={isSubmitting}>
                                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
             </Modal>
            }

            { displayShuddownModal && <Modal show={displayShuddownModal} onHide={hideConfirmationModal} className="modal" id="add_new_inspection">
                <div className="modal-header">
                    <h5 className="modal-title" id="addnewclientsLabel">Edit Shutdown</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                </div>
                <div className="modal-body">
                    <form id='inspectionFindingsform' name='inspectionFindingsform' onSubmit={handleSubmit(onInspSubmit)}>
                        <input type="hidden" name="tabname" { ...register('tabname') } value='edit_shutdown' />
                        <input type="hidden" name="site_id" { ...register('site_id') } value={site_id} />

                        <div className="row mb-1 justify-content-center">
                            <label htmlFor="date" className="col-form-label">Date of Last Shutdown Servicing<strong className="text-danger"> *</strong></label>
                            <div className="">
                                <DatepickerComponent type="date" name="last_shutdown" maxDate={new Date()} defVal={lastShutdown} form={form} required="true" errors={errors.last_shutdown} /> 
                            </div>
                        </div>
                        <div className="row mb-1 justify-content-center">
                            <label htmlFor="date" className="col-form-label">Due date for Next Shutdown Servicing<strong className="text-danger"> *</strong></label>
                            <div className="">
                                <DatepickerComponent type="date" name="next_shutdown" defVal={nextShutdown} form={form} required="true" errors={errors.next_shutdown} /> 
                            </div>
                        </div>

                        <div className="row mb-1 justify-content-center mt-3">
                            <div className="col-md-12">
                                <div className="text-end">
                                    <button type="submit" className="btn btn-primary px-4" disabled={isSubmitting}>
                                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
             </Modal>
            }
        </>
    )
}
export default memo(SummaryStatus)