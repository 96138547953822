import React, { useState,useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Context } from '../../components/Context';
import { toastNotify } from '../../components/Helper';
import { useForm } from 'react-hook-form';
import { fetchData }  from '../../components/Services/Api';

function ResetPassword(){
    window.document.title = "Reset Password | I2R Portal";
    const navigate = useNavigate();
    const [tinyloader, setTinyloader] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [user] = useContext(Context);

    async function onPrestPwdSubmit(data) {
        setTinyloader(true);
        try {
            data['id'] = user.id;
            fetchData('reset-profile-password', data,'post', (responce)=> {
                if(responce.success){
                    toastNotify('success', responce.message);
                    navigate('../dashboard');
                }else{
                    if(responce.message === 'validation_error'){
                        [responce.data].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    }
                    else {
                        toastNotify('danger', responce.message)
                    }
                }
                setTinyloader(false);
            }); 
        } catch (err) {
            toastNotify('danger', 'Oops! something went wrong, please try again later.');
            setTinyloader(false);
        }
    }

    return(
        <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="entry-box bg-white p-3 p-sm-4 p-sm-5 shadow-sm border-top border-warning border-3">
                            <form onSubmit={handleSubmit(onPrestPwdSubmit)} autoComplete="off">
                                <h4 className="text-center my-3">Reset Password</h4>
                                <label className="input-group input-group-lg input-group-rounded mb-3">
                                    <span className="input-group-text rounded-0">
                                        <svg className="icon tscale-1p2 opacity-50" role="img" aria-label="more">
                                            <use href="#icon_lock" />
                                        </svg>
                                    </span>
                                    <input type="password" placeholder="New Password" {...register('password', { required: true })} maxLength={32} className={`form-control rounded-0 ${errors.password && 'is-invalid'}` } />
                                </label>
                                <label className="input-group input-group-lg input-group-rounded mb-3">
                                    <span className="input-group-text rounded-0">
                                        <svg className="icon tscale-1p2 opacity-50" role="img" aria-label="more">
                                            <use href="#icon_lock" />
                                        </svg>
                                    </span>
                                    <input type="password" placeholder="Confirm Password" {...register('password_confirmation', { required: true })} maxLength={32} className={`form-control rounded-0 ${errors.password_confirmation && 'is-invalid'}` } />
                                </label>
                                <button type="submit" className="btn btn-primary btn-lg w-100 rounded-0 mb-3" disabled={tinyloader}>
                                    { tinyloader && <span className="spinner-border spinner-border-sm mr-1"></span> } Submit 
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword