import React, { useState, useEffect } from "react";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatepickerComponent = (props) => {

const [startDate, setStartDate] = useState('');
const [startTime, setStartTime] = useState('');
const todayDate = new Date().toISOString().slice(0, 10);

useEffect(() => {
  if((props.defDate && props.defDate !== '' && props.defDate !== '0000-00-00') || props.defVal && props.defVal !== '' && props.defVal !== '0000-00-00' ){
    setStartDate(new Date(props.defDate));
  }else{
    setStartDate('');
  }
  if(props.defTime && props.defTime !== '' && (props.defTime).includes(':')){
    let start_time = new Date(`${todayDate} ${props.defTime}`)
    setStartTime(start_time);
  }else{
    setStartTime('');
  }
},[props.defDate, props.defTime]);

const fieldName = props.name ? props.name : 'date';

if(props.type && props.type === 'time'){
  var currentDataFormate = props.formate ? props.formate : 'h:mm aa';
}else{
  var currentDataFormate = props.formate ? props.formate : 'dd/MM/yyyy'; 
}
if(startDate && startDate !='' && currentDataFormate){
  props.form.setValue(fieldName, format(startDate, currentDataFormate));
}
else if(startTime && startTime !='' && currentDataFormate){
  props.form.setValue(fieldName, format(startTime, currentDataFormate));
}else{
  props.form.setValue(fieldName, '');
}

if(fieldName){

    if(props.type && props.type === 'time'){

      return (
        <div className="position-relative" htmlFor={fieldName}>
          <DatePicker
            selected={startTime}
            placeholderText={props.placeholder && props.placeholder !== undefined ? props.placeholder : 'XX:XX AM'} 
            name={fieldName} 
            {...props.form.register(fieldName, {required: (props.required && props.required === "true") ? true : false })} 
            onChange={(time) => setStartTime(time)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeCaption="Time"
            dateFormat={currentDataFormate} 
            className={props.errors && !startTime ? 'is-invalid form-control' : 'form-control' }
            spellCheck="false"
          />
          <svg className="icon position-absolute end-0 me-1 pe-none top-50 translate-middle opacity-75">
            <use href="#icon_clock"></use>
          </svg> 
        </div>
      );

    }else{

      return (
        <div className="position-relative" htmlFor={fieldName}>
        <DatePicker 
          selected={startDate} 
          placeholderText={props.placeholder && props.placeholder !== undefined ? props.placeholder : 'DD/MM/YYYY'}  
          name={fieldName} 
          {...props.form.register(fieldName, {required: (props.required && props.required === "true") ? true : false })}
          onChange={(date) => { props.confirmStartData && props.confirmStartData(date); setStartDate(date)} } 
          minDate={props.minDate ? props.minDate : null} 
          maxDate={props.maxDate ? props.maxDate : null} 
          dateFormat={currentDataFormate}
          className={props.errors && !startDate ? 'is-invalid form-control' : 'form-control' } 
          spellCheck="false" 
        />
        <svg className="icon position-absolute end-0 me-1 pe-none top-50 translate-middle opacity-75">
          <use href="#icon_calendar"></use>
        </svg>
      </div>
      );
    }
  }
}

export default DatepickerComponent